<!-- modal header -->
<div class="modal-header">
    <h3 class="modal-title">{{ title }}</h3>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<!-- modal body -->
<div class="modal-body">
    {{ message }}
</div>

<!-- modal footer -->
<div class="modal-footer">
    <button *ngIf="btnCancelText" (click)="decline()" class="btn btn-danger">{{ btnCancelText }}</button>
    <button type="button" *ngIf="btnOkText" class="btn btn-success" (click)="accept()">{{ btnOkText }}</button>
</div>