<!-- Sub header -->
<section class="sub-header">

    <!-- Show the back btn -->
    <button class="btn btn-back" (click)="location.back()">
        Back
    </button>

    <!-- client name-->
    <h2>{{illustration?.clientName}}</h2>

</section>

<!-- body card-->
<section class="card mb-3 upload-box" *ngIf="illustration">
    <div class="card-body">
        <div class="line-of-2">
            <div>
                <div *ngIf="illustration.illustrationRevisions[0]?.requestDate" class="mb-3">
                    <h3>Request date</h3>
                    <span>{{ illustration.illustrationRevisions[0]?.requestDate | date }}</span>
                </div>

                <div *ngIf="illustration.illustrationStage.name" class="mb-3">
                    <h3>Stage</h3>
                    <span>{{ illustration.illustrationStage.name }}</span>
                </div>

                <div *ngIf="illustration.salesRepresentative?.displayName" class="mb-3">
                    <h3>Sales representative</h3>
                    <span>{{ illustration.salesRepresentative?.displayName }}</span>
                </div>

                <div *ngIf="illustration.supportRepresentative?.displayName" class="mb-3">
                    <h3>Support representative</h3>
                    <span>{{ illustration.supportRepresentative?.displayName }}</span>
                </div>

                <div *ngIf="illustration.networkMember?.accountName" class="mb-3">
                    <h3>Network member</h3>
                    <span>{{ illustration.networkMember?.accountName }}</span>
                </div>


            </div>

            <div>
                <div class="mb-3">
                    <h3>Existing Insurope pool</h3>
                    <span>{{ illustration.insuropeTypeOfBusiness? 'Yes' : 'No' }}</span>
                </div>

                <div class="mb-3">
                    <h3>Insurope pooled plan renewal</h3>
                    <span>{{ illustration.renewal? 'Yes' : 'No' }}</span>
                </div>

                <div class="mb-3" *ngIf="illustration.illustrationRevisions[0].illustrationRevisionOptions[0]">
                    <h3>Pooling system</h3>
                    <span>{{
                        illustration.illustrationRevisions[0].illustrationRevisionOptions[0].optionGeneralOutput.poolingSystem
                        }}</span>
                </div>

                <div *ngIf="illustration.commonCurrencyId" class="mb-3">
                    <h3>Common Currency</h3>
                    <span>{{ illustration.commonCurrencyId }}</span>
                </div>

                <div class="mb-3">
                    <h3>Poolable</h3>
                    <span>{{ illustration.illustrationRevisions[0].poolable? 'Yes' : 'No' }}</span>
                </div>
            </div>
            <!-- Files -->
            <app-attachment-list [illustrationAttachments]="illustrationAttachments" [service]="illustrationService"></app-attachment-list>
        </div>

        <div class="mt-3">
            <h3 class="mb-1">Finalized Mpp</h3>
            <div *ngIf="loadingMppFile" class="spinner-border spinner-border-sm mr-1" role="status"></div>
            <table class="table table-borderless" id="excel-table" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">

                <thead>
                    <tr class="table-head">
                        <th class="mr-10">Illustration version</th>
                        <th>Finalization date</th>
                        <th>Mpp files </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let revision of revisionOptionMpp">
                        <td class="col-cell">
                            <div>
                                <span><strong>{{ revision.revisionNumber}}</strong></span>
                            </div>
                        </td>
                        <td class="col-cell">
                            <div>
                                <span><strong>{{ revision.finalizedDate | date }}</strong></span>
                            </div>
                        </td>

                        <td *ngIf="revision.filesPath.length > 0">
                            <div *ngFor="let file of revision.filesPath; let i = index" (click)="downloadMppFile(file)">
                                <a class="attachment">
                                    {{documentService.sanitizeFileName(file,".pdf")}}
                                </a>
                            </div>
                        </td>
                        <td *ngIf="revision.filesPath.length == 0">
                            <p>No mpp file</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- status comments -->
        <div *ngIf="illustration.statusComment" class="mb-3">
            <h3>Additional comments</h3>
            <span>{{ illustration.statusComment }}</span>
        </div>
    </div>
</section>

<!-- Show the comment component-->
<app-comment-list *ngIf="illustration" [taskId]="illustration.id" [clientName]="illustration.clientName" [networkMemberName]="illustration.networkMember?.accountName" [networkMemberId]="illustration.networkMember?.id"
    [illustrationRequestSubscribers]="taskSubscribers" customSubscriberProvider="illustration" [isTagSystemApplicable]="false"></app-comment-list>