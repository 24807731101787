export enum ProcessIds {
    poolingPermissions = "pooling-permission",
    illustrationInputs = "illustration-input",
    accountInputs = "account-input",
    approvals = "approval",
    payments = "payments",
    serviceFees = "service-fees",
    annualReport = "annual-report",
    illustrations = "illustrations",
    multipoolPayments = "payments-mu",
    multipoolApprovals = "approval-mu"
}