import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss']
})
export class FileUploadButtonComponent implements OnInit {

  // needed import params
  @Input() fileTypes: string = "";
  @Input() acceptMultipleFiles: boolean = false;
  @ViewChild('uploadInput') uploadInput;
  // declare output params
  @Output() fileUploaded = new EventEmitter<{ isUploaded: boolean, message: string, files?: File[] }>();
  // constructor
  constructor() { }

  // oninit
  ngOnInit(): void {
  }

  // called when uploading files
  onFileSelected(event) {
    // notify parent if files has been uploaded
    if (event.target.files.length > 0) {
      let files: File[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        files.push(event.target.files[i]);
      }
      this.fileUploaded.emit({ isUploaded: true, message: event.target.files.length + " file(s) have been selected", files: files });
    }
    else
      this.fileUploaded.emit({ isUploaded: false, message: "no files were selected" });
    this.uploadInput.nativeElement.value = '';
  }

}
