<!-- Show the title -->
<h1>Collaboration platform</h1>

<!-- Show temporary links -->
<a [routerLink]="'account-inputs'">Account inputs</a>
<br>
<a [routerLink]="'pooling-permissions'">Pooling permissions</a>

<!-- Titles -->
<h1>I'm a <code>h1</code>, I'm only used in the header</h1>
<h2>I'm a <code>h2</code>, I'm used as page title.</h2>
<h3>I'm a <code>h3</code>, I'm used as a box or card title</h3>
<a href="#"
  ><h3>I'm a clickable <code>h3</code></h3></a
>
<h4>I'm a <code>h4</code>, I'm used as a card subtitle</h4>

<hr />

<!-- Buttons -->
<p>
  <code>.btn .btn-primary .btn-action</code> is used when there is an action
  behind the CTA
</p>
<button class="btn btn-primary btn-action">Click</button>
<p class="mt-3">
  <code>.btn .btn-secondary .btn-page</code> is used when, by clicking on this
  CTA, you navigate to an other page
</p>
<button class="btn btn-secondary btn-page">Click</button>

<hr />

<!-- Cards -->
<div class="card-table">
  <a href="#" class="card">
    <div class="card-body">
      <h3>Card title</h3>
      <h4>Card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status finalized">Finalized</p>
    </div>
  </a>
  <a href="#" class="card">
    <div class="card-body">
      <h3>Card title</h3>
      <h4>Card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status requested">Requested</p>
    </div>
  </a>
  <a href="#" class="card">
    <div class="card-body">
      <h3>Card title</h3>
      <h4>Very long and useless card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status unpaid">Unpaid</p>
    </div>
  </a>
  <a href="#" class="card">
    <div class="card-body">
      <h3>Card title</h3>
      <h4>Card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status finalized">Finalized</p>
    </div>
  </a>
  <a href="#" class="card">
    <div class="card-body">
      <h3>Very long card title</h3>
      <h4>Card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status finalized">Finalized</p>
    </div>
  </a>
  <a href="#" class="card">
    <div class="card-body">
      <h3>Card title</h3>
      <h4>Card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status finalized">Finalized</p>
    </div>
  </a>
  <a href="#" class="card">
    <div class="card-body">
      <h3>Card title</h3>
      <h4>Card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status finalized">Finalized</p>
    </div>
  </a>
  <a href="#" class="card">
    <div class="card-body">
      <h3>Card title</h3>
      <h4>Card subtitle</h4>
      <p>John Smith</p>
    </div>
    <div class="card-footer">
      <p>04 mar 2022</p>
      <p class="status finalized">Finalized</p>
    </div>
  </a>
</div>

<code class="mt-3 d-flex">
  &lt;div class="card-table"&gt;&nbsp;&nbsp;&lt;!-- displays the cards in a grid
  --&gt;<br />
  &nbsp;&nbsp;&lt;a href="#" class="card"&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-body"&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h3&gt;Card title&lt;/h3&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h4&gt;Card subtitle&lt;/h4&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;John Smith&lt;/p&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&lt;div class="card-footer"&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;04 mar 2022&lt;/p&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p class="status
  finalized"&gt;Finalized&lt;/p&gt;<br />
  &nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;<br />
  &nbsp;&nbsp;&lt;/a&gt;<br />
  &lt;/div&gt;<br />
</code>

<hr />

<!-- Content -->
<p>Welcome to your new single-page application, built with:</p>
<ul>
  <li>
    <a href="https://get.asp.net/">ASP.NET Core</a> and
    <a href="https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx">C#</a> for
    cross-platform server-side code
  </li>
  <li>
    <a href="https://angular.io/">Angular</a> and
    <a href="http://www.typescriptlang.org/">TypeScript</a> for client-side code
  </li>
  <li>
    <a href="http://getbootstrap.com/">Bootstrap</a> for layout and styling
  </li>
</ul>
<p>To help you get started, we've also set up:</p>

<hr />

<!-- Forms -->
<h3>Small sized input used as filter</h3>
<p class="mb-3">
  Add <code>.small-form</code> class to the
  <code>div.form-group</code> containing each label and/or input
</p>
<!-- Input -->
<div class="form-group small-form">
  <label for="test"><strong>Not mandatory</strong> label</label>
  <input
    type="text"
    class="form-control"
    id="test"
    placeholder="Not mandatory except if ther is no label"
  />
</div>
<h3>Regular sized input used everywhere else</h3>
<p class="mb-3">Add nothing!</p>
<div class="form-group">
  <label for="test"><strong>Not mandatory</strong> label</label>
  <input
    type="text"
    class="form-control"
    id="test"
    placeholder="Not mandatory except if ther is no label"
  />
</div>
<!-- Text Area -->
<h3 class="mb-3">Textarea</h3>
<div class="form-group">
  <label for="comment"><strong>Not mandatory</strong> label</label>
  <textarea class="form-control" rows="5" id="comment"></textarea>
</div>
<!-- Select -->
<h3 class="mb-3">Select</h3>
<div class="form-group">
  <select id="inputSelect" class="form-control">
    <option selected>Choose...</option>
    <option>...</option>
  </select>
</div>
<!-- Checkbox -->
<h3 class="mb-3">Checkboxes</h3>
<div class="form-group">
  <div class="form-check">
    <input type="checkbox" value="" id="opt1" name="check-group" />
    <label for="opt1">Option 1</label>
  </div>
  <div class="form-check">
    <input type="checkbox" value="" id="opt2" name="check-group" />
    <label for="opt2">Option 2</label>
  </div>
  <div class="form-check">
    <input type="checkbox" value="" id="opt3" name="check-group" disabled />
    <label for="opt3">Option 1</label>
  </div>
</div>
<!-- Radio -->
<h3 class="mb-3">Radios</h3>
<div class="form-group">
  <div class="form-check">
    <input type="radio" value="" id="opt4" name="radio-group" />
    <label for="opt4">Option 1</label>
  </div>
  <div class="form-check">
    <input type="radio" value="" id="opt5" name="radio-group" />
    <label for="opt5">Option 2</label>
  </div>
  <div class="form-check">
    <input type="radio" value="" id="opt6" name="radio-group" disabled />
    <label for="opt6">Option 1</label>
  </div>
</div>
<!-- Switch -->
<h3 class="mb-3">Switch</h3>
<label class="switch">
  <p>Option 1</p>
  <input type="checkbox">
  <span class="slider"></span>
  <p>Option 2</p>
</label>
<label class="switch colored">
  <p>Option 1</p>
  <input type="checkbox">
  <span class="slider"></span>
  <p>Option 2</p>
</label>
