import { Injectable } from '@angular/core';
import { EmbeddedEnvelope } from 'src/app/models/EmbeddedEnvelope';
import { DocuSignClient } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root'
})
export class ESignatureService {

  // constructor
  constructor(private docuSignClient: DocuSignClient) {
  }

  async getEmbeddedUrl(embeddedEnvelop: EmbeddedEnvelope) {

    let embeddedUrl = await this.docuSignClient.getDocumentToSignUrl(embeddedEnvelop.SignerName, embeddedEnvelop.SignerEmail, embeddedEnvelop.SignerClientId,
      embeddedEnvelop.ReturnUrl, embeddedEnvelop.DocPdf).toPromise();
    return embeddedUrl;
  }
}