import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { faEye } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  constructor(public location: Location) {}

  faEye = faEye;

  ngOnInit(): void {}
}
