import { Injectable } from '@angular/core';
import { AccountsClient } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  // constructor
  constructor(private accountsClient: AccountsClient) { }

  // get account by Id
  async getAccountById(id: string) {
    return await this.accountsClient.getAccountById(id).toPromise();
  }

  // get the accounts by name
  async getAccountsByName(name: string, page: number, pageSize: number)
  {
    return await this.accountsClient.findAccountsByName(name, page, pageSize).toPromise();
  }

  // get the pooled and prospect accounts by name
  async getPooledAndProspectAccountsByName(name: string, page: number, pageSize: number)
  {
    return await this.accountsClient.findPooledAndProspectAccountsByName(name, page, pageSize).toPromise();
  }

  // get account excel file
  async getAccountExcelFile(accountNumber: string) {
    return await this.accountsClient.getAccountExcelFile(accountNumber).toPromise();
  }
  
  // get account expansion plan
  async getAccountExpansionPlan(accountNumber: string, page: number, pageSize: number, sortColumn: string, sortDirection: string) {
    return await this.accountsClient.getAccountExpansionPlan(accountNumber, sortDirection, sortColumn, page, pageSize).toPromise();
  }
}
