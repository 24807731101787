import { Component, Input, OnInit } from "@angular/core";
import {
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { FaqService } from "src/app/services/faq.service";
import { FaqCategoryDto, QuestionDto } from "src/app/web-api-client";

@Component({
  selector: "ngbd-modal-basic",
  templateUrl: "./modal-basic.component.html",
})
export class ModalBasicComponent implements OnInit {
  @Input() category: FaqCategoryDto;
  @Input() question: QuestionDto;
  @Input() isQuestion: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public faqService: FaqService
  ) {}
  closeResult = "";

  ngOnInit() {
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  saveCat() {
    this.faqService.updateFaqCateogry(this.category);
    this.activeModal.close(false);
  }

  deleteCat() {
    this.faqService.deleteFaqCategory(this.category.id);
    this.activeModal.close(false);
  }

  saveQuest() {
    this.faqService.updateQuestion(this.category.id, this.question);
    this.activeModal.close(false);
  }

  deleteQuest() {
    this.faqService.deleteQuestion(this.category.id, this.question.id);
    this.activeModal.close(true);
  }

  close() {
    this.activeModal.close(false);
  }
}
