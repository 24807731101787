<div class="d-flex  flex-wrap justify-content-between">
    <!--Attachments retrived from Task-->
    <div *ngIf="attachments?.length > 0" class="card d-flex flex-grow-1" style="width: 25%;">
        <div class="card-header">
            <h3 class="h5 mb-0">Attachments
                <div *ngIf="downloadingFile" class="spinner-border spinner-border-sm ml-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </h3>
        </div>
        <ul class="list-group list-group-flush">
            <li *ngFor="let file of attachments" class="list-group-item d-flex align-items-center btn-link">
                <span (click)="downloadAdminDbFile(file.path)">{{ this.documentService.sanitizeFileName(file.name) }}</span>
                <span class=" ms-2" *ngIf="formatdate(file.fileDate)">at {{ formatdate(file.fileDate) }}</span>
                <button class="btn btn-link p-0" (click)="downloadAdminDbFile(file.path)">
                    <fa-icon class="ms-2" [icon]="faDownload" size="sm"></fa-icon>
                </button>
            </li>
        </ul>
    </div>

    <!--Attachments retrived from AdminDB-->
    <div *ngIf="adminDbAttachments?.length > 0" class="card d-flex flex-grow-1" style="width: 25%;">
        <div class="card-header">
            <h3 class="h5 mb-0">Accounting Attachment
                <div *ngIf="downloadingFile" class="spinner-border spinner-border-sm ml-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </h3>
        </div>
        <ul class="list-group list-group-flush">
            <li *ngFor="let filePath of adminDbAttachments" class="list-group-item d-flex align-items-center btn-link">
                <span (click)="downloadAdminDbFile(filePath)">{{ this.documentService.sanitizeFileName(filePath) }}</span>
                <button class="btn btn-link p-0" (click)="downloadAdminDbFile(filePath)">
                    <fa-icon class="ms-2" [icon]="faDownload" size="sm"></fa-icon>
                </button>
            </li>
        </ul>
    </div>

    <!--Attachments retrived from Comment-->
    <div *ngIf="commentAttachments?.length > 0" class="card d-flex flex-grow-1" style="width: 25%;">
        <div class="card-header">
            <h3 class="h5 mb-0">Discussion Attachment
                <div *ngIf="downloadingFile" class="spinner-border spinner-border-sm ml-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </h3>
        </div>
        <ul class="list-group list-group-flush">
            <li *ngFor="let file of commentAttachments" class="list-group-item d-flex align-items-center btn-link">
                <span (click)="downloadCommentFile(file)">{{ this.documentService.sanitizeFileName(file?.name) }}</span>
                <span class="ms-2" *ngIf="formatdate(file.fileDate)">at {{ formatdate(file.fileDate) }}</span>
                <button class="btn btn-link p-0" (click)="downloadCommentFile(file)">
                    <fa-icon class="ms-2" [icon]="faDownload" size="sm"></fa-icon>
                </button>
            </li>
        </ul>
    </div>

    <!--Attachments retrived from Illustration-->
    <div *ngIf="illustrationAttachments?.length > 0" class="card d-flex flex-grow-1" style="width: 25%;">
        <div class="card-header">
            <h3 class="h5 mb-0">Illustration Attachment
                <div *ngIf="downloadingFile" class="spinner-border spinner-border-sm ml-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </h3>
        </div>
        <ul class="list-group list-group-flush">
            <li *ngFor="let filePath of illustrationAttachments" class="list-group-item d-flex align-items-center btn-link">
                <span (click)="downloadFileFromPath(filePath)">{{ this.documentService.sanitizeFileName(filePath) }}</span>
                <button class="btn btn-link p-0" (click)="downloadFileFromPath(filePath)">
                    <fa-icon class="ms-2" [icon]="faDownload" size="sm"></fa-icon>
                </button>
            </li>
        </ul>
    </div>
</div>

<div *ngIf="(attachments?.length == 0 && commentAttachments?.length ==0 && illustrationAttachments?.length ==0 && adminDbAttachments?.length == 0)" class="mb-3 files-list">
    <p>No files for this task</p>
</div>