<nav class="app-nav shadowed-box" [ngClass]="isExpanded ? 'visible-nav' : 'hidden-nav'"
  (window:resize)="onResize($event)">
  <div>
    <a class="toggling-nav shadowed-box" [ngClass]="isExpanded ? 'visible-nav' : 'hidden-nav'" (click)="toggleNav()"
      *ngIf="!isDisabled">
      <span class="line"></span>
      <span class="line"></span>
      <span class="line"></span>
    </a>
    <div class="logo">
      <a [routerLink]="['']"><img src="assets/logo.png" alt="logo" /></a>
    </div>
    <ul class="main-nav">
      <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="['']">
          <span class="icon">
            <fa-icon [icon]="faEye"></fa-icon>
          </span>
          Overview
        </a>
      </li>
      <li class="nav-item">
        <a data-bs-toggle="collapse" data-bs-target="#multiCollapse0" role="button" aria-expanded="false"
          aria-controls="multiCollapse0"><span class="icon">
            <fa-icon [icon]="faHandshake"></fa-icon>
          </span> Open tasks<span class="chevron">
            <fa-icon [icon]="faChevronDown"></fa-icon>
          </span></a>

        <!-- Sales -->
        <ul class="collapse" id="multiCollapse0">
          <li class="nav-item">
            <a data-bs-toggle="collapse" data-bs-target="#multiCollapse1" role="button" aria-expanded="false"
              aria-controls="multiCollapse1">Sales<span class="chevron">
                <fa-icon [icon]="faChevronDown"></fa-icon>
              </span></a>
            <ul class="collapse" id="multiCollapse1">
              <li *ngIf="isNetworkMember" class="nav-item" [routerLinkActive]="['link-active']">
          <a [routerLink]="['/illustration-request']">Illustration request</a>
        </li>
              <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="!isClient">
                <a [routerLink]="['/pooling-permission']">Pooling permissions</a>
              </li>
              <li class="nav-item" [routerLinkActive]="['link-active']">
                <a [routerLink]="['/illustration-input']">Illustration inputs</a>
              </li>
            </ul>
          </li>

          <!-- Accounting -->
          <li class="nav-item" *ngIf="!isClient">
            <a data-bs-toggle="collapse" data-bs-target="#multiCollapse2" role="button" aria-expanded="false"
              aria-controls="multiCollapse2">Accounting<span class="chevron">
                <fa-icon [icon]="faChevronDown"></fa-icon>
              </span></a>
            <ul class="collapse" id="multiCollapse2">
              <li class="nav-item" [routerLinkActive]="['link-active']">
                <a [routerLink]="['/account-input']">Account Input</a>
              </li>
              <li class="nav-item" [routerLinkActive]="['link-active']">
                <a [routerLink]="['/approval']">Approvals</a>
              </li>
            </ul>
          </li>

          <!-- Finance -->
          <li class="nav-item" *ngIf="!isClient">
            <a data-bs-toggle="collapse" data-bs-target="#multiCollapse3" role="button" aria-expanded="false"
              aria-controls="multiCollapse3">Finance<span class="chevron">
                <fa-icon [icon]="faChevronDown"></fa-icon>
              </span></a>
            <ul class="collapse" id="multiCollapse3">
              <li class="nav-item" [routerLinkActive]="['link-active']">
                <a [routerLink]="['/payments']">LS Payments</a>
              </li>
              <li class="nav-item" *ngIf="isFinancial" [routerLinkActive]="['link-active']">
                <a [routerLink]="['/service-fees']">Service fees</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <!-- Deliverables -->
      <li class="nav-item">
        <a data-bs-toggle="collapse" data-bs-target="#multiCollapse4" role="button" aria-expanded="false"
          aria-controls="multiCollapse4"><span class="icon">
            <fa-icon [icon]="faEnvelope"></fa-icon>
          </span>MPP & Reports<span class="chevron">
            <fa-icon [icon]="faChevronDown"></fa-icon>
          </span></a>
        <ul class="collapse" id="multiCollapse4">
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a [routerLink]="['/all-illustrations']">Illustrations</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a [routerLink]="['/finalized-accounts']">Annual reports</a>
          </li>
        </ul>
      </li>

      <!-- Clients -->
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <a [routerLink]="['/clients']"><span class="icon">
            <fa-icon [icon]="faMugHot"></fa-icon>
          </span>Clients</a>
      </li>

      <!-- Reports -->
      <li class="nav-item" *ngIf="isDevelopment">
        <a data-bs-toggle="collapse" data-bs-target="#multiCollapse5" role="button" aria-expanded="false"
          aria-controls="multiCollapse5"><span class="icon">
            <fa-icon [icon]="faCalculator"></fa-icon>
          </span>Statistics<span class="chevron">
            <fa-icon [icon]="faChevronDown"></fa-icon>
          </span></a>
        <ul class="collapse" id="multiCollapse5" *ngIf="!isClient">
          <li id="{report.reportName}" class="nav-item" [routerLinkActive]="['link-active']"
            style="list-style-type: none;" *ngFor="let report of reportsSubMenuItems">
            <a [routerLink]="['/reports/'+report.reportName]"><span class="icon">
                <fa-icon [icon]="faChartLine"></fa-icon>
              </span>{{report.reportName}}</a>
          </li>
        </ul>
      </li>

      <!-- Important files -->
      <!--<li class="nav-item" [routerLinkActive]="['link-active']">
        <a [routerLink]="['/important-files']"><span class="icon">
            <fa-icon [icon]="faFile"></fa-icon>
          </span>Info</a>
      </li>-->

      <!--  <li class="nav-item" [routerLinkActive]="['link-active']">
      <a [routerLink]="['/messages']"><span class="icon">
          <fa-icon [icon]="faEnvelope"></fa-icon>
        </span>Messages</a>
    </li> -->
      <!--       <li class="nav-item" [routerLinkActive]="['link-active']">
        <a [routerLink]="['/e-sign']"><span class="icon">
            <fa-icon [icon]="faCircleQuestion"></fa-icon>
          </span>E-Sign</a>
      </li> -->

     <!-- <li class="nav-item" [routerLinkActive]="['link-active']">
        <a [routerLink]="['/faq']"><span class="icon">
            <fa-icon [icon]="faCircleQuestion"></fa-icon>
          </span>FAQ</a>
      </li>
      -->
    </ul>
    <app-footer></app-footer>
  </div>
</nav>