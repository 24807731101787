<!-- Show the comment form -->
<div class="row d-flex cta-container">
  <div class="col-md">
    <div class="list-of-n">
      <ng-container *ngFor="let tag of commentTags; let i = index;">
        <div class="subscriber-mail btn btn-info">
          <span class="adress-mail">{{tag}}</span>
          <span (click)="deleteTag(i)" class="delete-subscriber">
            <fa-icon [icon]="faCircleXmark" size="lg"></fa-icon>
          </span>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-md-2" *ngIf="isTagSystemApplicable">
    <input id="typeahead-focus" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)" (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead"
      placeholder="Add a tag" (keydown.enter)="addTag($event,$any($event).target.value)" (selectItem)="addTag($event,$event.item)" />
  </div>
</div>

<form [formGroup]="templateForm" (ngSubmit)="onClickSubmit()" class="m-3 pb-2">
  <div draggable="true" ngClass="{{dragAreaClass}}"></div>
  <quill-editor id="quill-editor" [styles]="{height: '300px',backgroundColor: '#ffffff' }" formControlName="textEditor" #quillEditor></quill-editor>
  <div class="d-flex cta-container">
    <app-file-upload-button (fileUploaded)="fileUploaded($event)" [acceptMultipleFiles]="true"></app-file-upload-button>
    <button [disabled]="!valid" class="btn btn-primary" type="submit">Send</button>
    <div *ngIf="isEdit" class="d-flex align-items-center">
      <button class="btn btn-primary me-3" (click)="cancelEdit()">Cancel</button>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="notficationFlag" [(ngModel)]="shoudlSendNotification" [ngModelOptions]="{standalone: true}">
        <label class="form-check-label h6" for="notficationFlag"><b>Send notification</b></label>
      </div>
    </div>
    <button type="button" style="margin-left: auto;" class="btn btn-primary" *ngIf="approval" (click)="getAccountFile()">Upload Account file</button>
  </div>

  <div class="d-flex flex-column ">
    <p *ngIf="!attachments">No files selected</p>
    <div id="files" *ngFor="let file of attachments; let i = index">{{ documentService.sanitizeFileName(file.fileName)}}
      <button type="button" class="btn-close align-middle" aria-label="Close" style="height: 2px; width: 2px;" (click)="removeFile(i)">
        <span aria-hidden="true"></span>
      </button>
    </div>
  </div>

  <p id="filesErrorMessage" *ngIf="showAdminDbError">No admin db file was found</p>
  <p id="filesErrorMessage" *ngIf="showError">You cannot add more than 30 MB of files. Please verify the size of your
    files</p>
</form>