import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PopupNotificationsService } from '../services/popup-notifications/popup-notifications.service';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

  // constructor
  constructor(private popupNotifications: PopupNotificationsService, private msalService: MsalService, private router: Router) { }

  // intercept http requests
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request)
      .pipe(map(res => {
        return res
      }),
        catchError((error: HttpErrorResponse) => {

          if (error.error instanceof ErrorEvent) {
            console.log('This is client side error');
            console.log(`Error: ${error.error.message}`);
          }
          else {
            console.log('This is server side error');
            console.log(`Error Code: ${error.status},  Message: ${error.message}`);
          }
          if (error.status == 403) {
            this.router.navigate(["unauthorized"]);
          }
          else if (error.status == 401) {
            setTimeout(() => { this.msalService.logoutRedirect(); }, 3000);
          }
          else {
            return throwError(error);
          }
        })
      );
  }
}
