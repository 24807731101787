<app-file-upload-task-detail *ngIf="!loadingTask"></app-file-upload-task-detail>

<section class="card mt-3 mb-3 account-input-list" *ngIf="!loadingTask && outstandingInputs?.length > 0">
  <div class="card-body">
    <div style="display: inline-block; height: 100%;
    width: 100%;" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg" aria-controls="table">
      <div class="expand"><fa-icon [icon]="this.gfg ? this.faChevronDown : this.faChevronUp"></fa-icon></div>
    </div>
    <button type="button" class="btn btn-primary mb-2" style="display: inline; float:left;" (click)="exportexcel()">Export to excel</button>
    <table class="table table-borderless" id="excel-table" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">

      <thead>
        <tr class="table-head">
          <th>Client name</th>
          <th>Country</th>
          <th>Pooling system</th>
          <th>Due date</th>
          <th>Send Network Member date</th>
          <th>Send Client date</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let outstandingInput of outstandingInputsToDisplay">
          <td class="col-cell" data-label="Client name">
            <div class="info-container">
              <span><strong>{{ outstandingInput.ClientName }}</strong></span>
              <span>{{ outstandingInput.SubsidiaryName }}</span>
            </div>
          </td>
          <td data-label="Country">{{ outstandingInput.CountryName }}</td>
          <td data-label="Pooling system">{{
            outstandingInput.PoolingSystem
            }}</td>
          <td data-label="Due date">{{ outstandingInput.DueDate | date: 'longDate' }}</td>
          <td data-label="Send Network Member date">{{ outstandingInput.SendNMDate| date }}</td>
          <td data-label="Send Client date">{{ outstandingInput.SendClientDate | date}}</td>
          <!-- <td><button type="button" class="btn btn-primary mb-2 col-md-3"(click)="addClientNameToTags(outstandingInput.ClientName)">Add tag</button></td> -->
        </tr>
      </tbody>

    </table>
  </div>
</section>

<div *ngIf="!loadingTask" class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn" (click)="loadMore()">
    <span *ngIf="loadingTask">Loading ...</span>
    <span *ngIf="!loadingTask">Load more</span>
  </button>
</div>

<app-comment-list *ngIf="!loadingTask" [taskId]="taskId" [networkMemberId]="networkMemberId" [networkMemberName]="networkMemberName" [clientName]="clientName" [service]="fileUploadTaskService"
  [isTagSystemApplicable]="false"></app-comment-list>

<app-loading-spinner *ngIf="loadingTask"></app-loading-spinner>