import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { FileUploadTaskDto } from 'src/app/web-api-client';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';
import { UserRoles } from 'src/app/models/enums/user-roles';

@Component({
  selector: 'app-pooling-permissions-list-page',
  templateUrl: './pooling-permissions-list-page.component.html',
  styleUrls: ['./pooling-permissions-list-page.component.scss']
})
export class PoolingPermissionsListPageComponent implements OnInit {

  process = "pooling-permission"
  items: FileUploadTaskDto[]
  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isCollaborator: boolean;
  statusFilter: number;
  isTableView: boolean = false;
  headers: { head: string, property: string }[] = [{ head: "Client", property: "title" }, { head: "Network Member", property: "networkMember?.accountName" }, { head: "Country", property: "country" }, { head: "Status", property: "status" }]
  isTableViewStoredValueKey: string;
  faTh = faTh;
  faBars = faBars;
  constructor(private userService: UserService,
    private titleService: Title) { }

  async ngOnInit() {

    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Pooling Permissions | Insurope");
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))

    this.changeIsTableViewValue(storedValue);
  }

  changeIsTableViewValue(value) {
    if (value == null) {
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    } else {
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }


}
