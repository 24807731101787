import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  acceptedCookies: boolean = false;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    this.checkCookieConsent();
  }

  checkCookieConsent(): void {
    const cookiesAccepted = this.cookieService.get('cookiesAccepted');
    this.acceptedCookies = cookiesAccepted === 'true';
  }

  acceptCookies(): void {
    this.cookieService.set('cookiesAccepted', 'true', 365, '/'); 
    this.acceptedCookies = true;
  }

}
