import { Injectable } from "@angular/core";
import {
  FaqCategoriesClient,
  FaqCategoryDto,
  QuestionClient,
  QuestionDto,
  UpdateFaqCommand,
  UpdateQuestionCommand,
} from "../web-api-client";

@Injectable({
  providedIn: "root",
})
export class FaqService {

  faqCategories: FaqCategoryDto[] = [];
  questions: QuestionDto[] = [];

  constructor(
    private faqCategoriesClient: FaqCategoriesClient,
    private questionClient: QuestionClient
  ) {}

  async getFaqCategories(): Promise<FaqCategoryDto[]> {
    this.faqCategories = await this.faqCategoriesClient
      .getFaqCategories()
      .toPromise();
    return this.faqCategories;
  }

  async addFaqCategory(categoryName: string) {
    let newCategory = new FaqCategoryDto();
    newCategory.id = await this.faqCategoriesClient.addFaqCategory(categoryName, this.faqCategories.length + 1).toPromise();
    newCategory.name = categoryName;
    newCategory.sortOrder = this.faqCategories.length + 1;
    newCategory.questions = [];
    this.faqCategories.push(newCategory);
  }

  async updateFaqCateogry(command: FaqCategoryDto) {
    await this.faqCategoriesClient.updateFaqCategory(command).toPromise();
  }

  getQuestionsOfCategory(categoryId: string) {
    return this.faqCategories.find((c) => c.id == categoryId)?.questions;
  }

  async deleteFaqCategory(categoryId: string) {
    this.faqCategories = this.faqCategories.filter(c => c.id != categoryId);
    await this.faqCategoriesClient.deleteFaqCategory(categoryId).toPromise();
  }

  async getQuestions(): Promise<QuestionDto[]> {
    this.questions = await this.questionClient.getQuestions().toPromise();
    return this.questions;
  }

  async addQuestion(catId: string, title: string, answer: string) {
    let question = new QuestionDto();
    question.id = await this.faqCategoriesClient.addFaqQuestion(catId, title, answer).toPromise();
    question.title = title;
    question.answer = answer;
    this.questions.push(question);
  }

  async updateQuestion(categoryId: string, question: QuestionDto) {
    let command = new UpdateQuestionCommand();
    command.categoryId = categoryId;
    command.question = question;
    await this.questionClient.updateQuestion(command).toPromise();
  }

  async deleteQuestion(categoryId: string, questionId: string) {
    await this.questionClient.deleteQuestion(categoryId, questionId).toPromise();
  }
}
