<div style="float: right; margin-left: 1em;">
  <label class="switch colored">
    <fa-icon [icon]="faTh"></fa-icon>
    <input type="checkbox" (click)="changeIsTableViewValue(!this.isTableView)"  [(ngModel)]="isTableView">
    <span class="slider"></span>
    <fa-icon [icon]="faBars"></fa-icon>
  </label>
  
</div>
<app-boolean-task-list [headers]="headers" [title]="'Approvals'" process="approval" [displayFilter]="true" [statusFilter]="null" [tableView]="isTableView" app-boolean-task-list>
