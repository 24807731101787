import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  // constructor
  constructor(
    public location: Location,
    private titleService: Title
  ) { }

  // OnInit
  ngOnInit(): void {
    // set the title
    this.titleService.setTitle("FAQ | Insurope");
  }

}
