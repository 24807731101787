import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FilterParameter } from "src/app/models/FilterPrameter";
import { FileParameter, FileUploadTaskDto, FileUploadTasksClient, SortingElement } from "src/app/web-api-client";

@Injectable({
  providedIn: "root",
})

export class FileUploadTaskService {
  // hold the active task
  activeFileUploadTask: FileUploadTaskDto;
  processId: string;

  // constructor
  constructor(private fileUploadTaskClient: FileUploadTasksClient, private router: Router) { }

  // get the tasks per process for NETWORK MEMBER
  async getMyOrganization(filterParameter: FilterParameter, sortingFields: SortingElement[], page: number, pageSize: number): Promise<FileUploadTaskDto[]> {
    // get the tasks by process
    return await this.fileUploadTaskClient
      .getMyOrganization(filterParameter.Id,
        filterParameter.SourceId,
        filterParameter.Title,
        filterParameter.SubTitle,
        filterParameter.DisplayDate,
        filterParameter.ProcessId,
        filterParameter.NetworkMemberId,
        filterParameter.ClientId,
        filterParameter.Country,
        filterParameter.Status,
        filterParameter.ClientName,
        filterParameter.NetworkMemberIds,
        filterParameter.SubscriberId,
        filterParameter.SearchDateLimit,
        filterParameter.OverdueDate,
        filterParameter.CreatedDate,
        filterParameter.PayTo,
        sortingFields,
        page,
        pageSize)
      .toPromise();
  }

  // get a task by Id
  async getFileUploadTaskById(id: string): Promise<FileUploadTaskDto> {
    if (!id) {
      return null;
    }

    try {
      // return the task
      return await this.fileUploadTaskClient.getById(id).toPromise();
    }
    catch (error) {
      this.router.navigate(['/404'], { skipLocationChange: true });
    }
  }

  // get a task by Id and set as active
  async getFileUploadTaskByIdAndSetActive(id: string): Promise<FileUploadTaskDto> {
    if (!id) {
      return null;
    }

    try {
      // save the task
      this.activeFileUploadTask = await this.fileUploadTaskClient.getById(id).toPromise();
      // return the task
      return this.activeFileUploadTask;
    }
    catch (error) {
      this.router.navigate(['/404'], { skipLocationChange: true });
    }
  }

  // upload a file
  async uploadFileToTask(task: FileUploadTaskDto, file: File, submit: boolean): Promise<string> {
    let $parameter: FileParameter;

    //configure paramater
    $parameter = { data: new Blob([file]), fileName: file.name };
    //upload file
    var filePath = await this.fileUploadTaskClient
      .uploadFile(task.id, task.processId, submit, $parameter)
      .toPromise();
    return filePath;
  }

  async downloadFile(taskId: string, fileId: string, systemAttachment: boolean) {
    return await this.fileUploadTaskClient.downloadFile(taskId, fileId, systemAttachment).toPromise();
  }

  async downloadFileFromPath(taskId: string, filePath: string) {
    return await this.fileUploadTaskClient.downloadFileFromPath(taskId, filePath).toPromise();
  }

  async getTasks(filterParameter: FilterParameter, sortingFields: SortingElement[], page: number, pageSize: number): Promise<FileUploadTaskDto[]> {
    return this.fileUploadTaskClient.getTasks(filterParameter.Id,
      filterParameter.SourceId,
      filterParameter.Title,
      filterParameter.SubTitle,
      filterParameter.DisplayDate,
      filterParameter.ProcessId,
      filterParameter.NetworkMemberId,
      filterParameter.ClientId,
      filterParameter.Country,
      filterParameter.Status,
      filterParameter.ClientName,
      filterParameter.NetworkMemberIds,
      filterParameter.SubscriberId,
      filterParameter.SearchDateLimit,
      filterParameter.OverdueDate,
      filterParameter.CreatedDate,
      filterParameter.PayTo,
      sortingFields,
      page,
      pageSize)
      .toPromise();
  }

  async getSubscribersEmails(taskId: string) {
    return this.fileUploadTaskClient.getTaskSubscribersByTaskId(taskId).toPromise();
  }
  async changeTaskStatus(id: string, status: number) {
    return await this.fileUploadTaskClient.changeTaskStatus(id, status).toPromise()
  }

  // remove a file
  /**
  removeFile(task: FileUploadTask, file: string | ArrayBuffer) {
    // get the files of the task
    let files = this.fileUploadTasks.find(t => t.Id == task.Id).Files;
    // remove the file from the list and save to the files of the task
    this.fileUploadTasks.find(t => t.Id == task.Id).Files = files.filter(f => f.content != file);
  }*/
}
