import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { UserService } from 'src/app/services/user.service';
import { FileUploadTaskDto } from 'src/app/web-api-client';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-account-input-list-page',
  templateUrl: './account-input-list-page.component.html',
  styleUrls: ['./account-input-list-page.component.scss']
})
export class AccountInputListPageComponent implements OnInit {

  isTableViewStoredValueKey: string;
  process = "account-input"
  items: FileUploadTaskDto[]
  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isCollaborator: boolean;
  statusFilter: number;
  isTableView: boolean = false;
  headers: { head: string, property: string }[] = [{ head: "Remaining", property: "title" }, { head: "Network Member", property: "networkMember?.accountName" }, { head: "Open account inputs", property: "subTitle" }, { head: "Status", property: "status" }]
  faTh = faTh;
  faBars = faBars;

  constructor(private userService: UserService, private titleService: Title) {
  }

  async ngOnInit() {
    // set the title
    this.titleService.setTitle("Account Inputs | Insurope");
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
    this.changeIsTableViewValue(storedValue);
  }

  changeIsTableViewValue(value) {
    if (value == null) {
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    } else {
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
}
