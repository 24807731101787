<section class="filter-labelled">
    <label for="selectPayToId">Payment To</label>
    <div class="d-flex filter-box custom-filter">
      <select class="form-control" name="selectPayTo" id="selectPayToId" [(ngModel)]='selectedPayTo'
        (ngModelChange)="selectPayTo($event)">
        <option *ngFor="let option of optionsPay" [value]="option">
            {{ this.commonService.getPayToText(option) }}
        </option>
      </select>
    </div>
</section>
