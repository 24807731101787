import { Injectable } from '@angular/core';
import { ContactsClient } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  // constructor
  constructor(private contactsClient: ContactsClient) { }

  // get primary contact from account
  async GetPrimaryContactFromAccount(accountId: string) {
    return this.contactsClient.getPrimaryContactFromAccount(accountId).toPromise();
  }
  
  // get consultant contact from account
  async GetConsultantContactFromAccount(accountId: string) {
    return this.contactsClient.getConsultantContactFromAccount(accountId).toPromise();
  }
}
