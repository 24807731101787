import { Injectable } from '@angular/core';
import { AccountDto, NetworkMembersClient } from 'src/app/web-api-client';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class NetworkMembersService {

  // constructor
  constructor(private networkMembersClient: NetworkMembersClient) { }

  // get the network members by name
  async getNetworkMembersByName(name: string, page: number, pageSize: number) {
    var networkMemberList: AccountDto[] = await this.networkMembersClient.findNetworkMembersByName(name, page, pageSize).toPromise();
    let sortedNetworkMemberList = _.sortBy(_.sortBy(networkMemberList, 'accountName'), 'isAdl');
    return sortedNetworkMemberList
  }

  // get the network member by id
  async getNetworkMemberById(id: string) {
    return await this.networkMembersClient.findNetworkMemberById(id).toPromise();
  }
}
