<!-- Pop up update category -->
<div *ngIf="!isQuestion">
  <div class="modal-header" type="type1">
    <strong class="modal-title">Update category</strong>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>Title of the category</label>
    <input type="faqCategory" [(ngModel)]="category.name" id="form12" class="form-control" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link text-danger" (click)="deleteCat()">Delete Category</button>
    <button type="button" class="btn btn-success" (click)="saveCat()">Save</button>
  </div>
</div>

<!-- Pop up of question -->
<div *ngIf="isQuestion">
  <div class="modal-header" type="type2">
    <strong class="modal-title">Update question</strong>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label class="mb-2">Question</label>
    <input type="text" [(ngModel)]="question.title" id="form12" class="form-control mb-2" />
    
    <label class="mb-2">Answer</label>
    <textarea type="text" [(ngModel)]="question.answer" id="form12" class="form-control" rows="8"></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link text-danger" (click)="deleteQuest()">Delete Question</button>
    <button type="button" class="btn btn-success" (click)="saveQuest()">Save</button>
  </div>
</div>