import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NetworkMembersService } from 'src/app/services/network-members/network-members.service';
import { AccountDto } from 'src/app/web-api-client';

@Component({
  selector: 'app-network-member-filter',
  templateUrl: './network-member-filter.component.html',
  styleUrls: ['./network-member-filter.component.scss']
})
export class NetworkMemberFilterComponent implements OnInit {

  // search indicators
  searchingNetworkMembers: boolean = false;
  filteredNetworkMembers: AccountDto[] = [];
  clickedOnNm: boolean = false;

  @Output() networkMemberEmitter = new EventEmitter<AccountDto>();
  @ViewChild('searchField', { static: true }) searchInputField: ElementRef;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public networkMemberService: NetworkMembersService) { }

  async ngOnInit() {
    var networkMemberId = this.activatedRoute.snapshot.queryParams['networkMemberId'] ?? localStorage.getItem('networkMemberId');

    if (networkMemberId) {
      localStorage.setItem('networkMemberId', networkMemberId);

      var account = await this.networkMemberService.getNetworkMemberById(networkMemberId);
      this.searchInputField.nativeElement.value = account.accountName;
    }

    fromEvent(this.searchInputField.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 1
      filter(res => res.length >= 2),
      // Time in milliseconds between key events
      debounceTime(50),
      // If previous query is diffent from current   
      distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.clickedOnNm = false;
      this.fetchNM(text);
    });

    fromEvent(this.searchInputField.nativeElement, 'keydown').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      filter(res => res.length < 1),
    ).subscribe((text: string) => {
      this.clearSearch();
    });
  }

  async fetchNM(term: string) {
    if (term === '') {
      return this.filteredNetworkMembers = [];
    }
    this.searchingNetworkMembers = true;
    this.filteredNetworkMembers = await this.networkMemberService.getNetworkMembersByName(term.toLowerCase(), 0, 30);
    this.searchingNetworkMembers = false;
  }

  async selectNm(nmId: string) {
    this.clickedOnNm = true;

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { networkMemberId: nmId },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.filteredNetworkMembers = [];

    var account = (await this.networkMemberService.getNetworkMemberById(nmId))
    localStorage.setItem('networkMemberId', account.id);

    this.searchInputField.nativeElement.value = account.accountName;
  }

  async loadNetworkMember(networkMember: AccountDto) {
    this.clickedOnNm = true;
    this.searchInputField.nativeElement.value = networkMember.accountName;

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { networkMemberId: networkMember.id },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.filteredNetworkMembers = [];
    this.networkMemberEmitter.emit(networkMember);
    this.searchInputField.nativeElement.value = networkMember.accountName;
  }

  async clearSearch() {
    localStorage.removeItem('networkMemberId');
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { networkMemberId: null },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.filteredNetworkMembers = [];
    this.searchInputField.nativeElement.value = "";
  }
}
