import { Injectable } from '@angular/core';
import { AccountsClient, CrmAttachmentsClient } from '../web-api-client';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  
  // constructor
  constructor(private accountsClient: AccountsClient, private crmAttachmentsClient: CrmAttachmentsClient) { }

  // get the clients by name
  async getClientsByName(name: string, pageNumber: number, pageSize: number) {
    return await this.accountsClient.findAccountXesByName(name, pageNumber, pageSize).toPromise();
  }

  // get the clients by name
  async getClientByName(clientName: string, clientId : string) {
    return await this.accountsClient.findAccountByName(clientId,clientName).toPromise();
  }

  // get the technical contact by external account number
  async getTechnicalContact(externalAccountNumber: string) {
    return await this.accountsClient.getTechnicalContactByExternalAccountNumber(externalAccountNumber).toPromise();
  }

  // get the contracts of the client
  async getClientContracts(accountId: string, pageNumber?: number, pageSize?: number) {
    return await this.crmAttachmentsClient.getCrmAccountAttachments(accountId, pageNumber, pageSize).toPromise();
  }
  
  // get the contractual documents of the client
  async getClientContractualDocuments(accountId: string, pageNumber?: number, pageSize?: number) {
    return await this.crmAttachmentsClient.getCrmAccountContractualAttachments(accountId, pageNumber, pageSize).toPromise();
  }
  
  // get the contractual documents of the client
  async getClientImportantDocuments(accountId: string, type: string) {
    return await this.crmAttachmentsClient.getCrmAccountImportantAttachments(accountId, type).toPromise();
  }

  // download an attachment
  async downloadAttachment(attachmentId: string) {
    return await this.crmAttachmentsClient.downloadCrmAttachment(attachmentId).toPromise();
  }
  async getClientById(clientId:string){
    return await this.accountsClient.getAccountById(clientId).toPromise();
  }

}
