import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";
import { IllustrationDetailComponent } from "./components/illustrations/illustration-detail/illustration-detail.component";
import { IllustrationListComponent } from "./components/illustrations/illustration-list/illustration-list.component";

import { BooleanTaskDetailComponent } from "./components/tasks/boolean-task/boolean-task-detail/boolean-task-detail.component";
import { FileUploadTaskDetailComponent } from "./components/tasks/file-upload-task/file-upload-task-detail/file-upload-task-detail.component";
import { InformativeDetailComponent } from "./components/tasks/informative-task/informative-detail/informative-detail.component";
import { HomeComponent } from "./home/home.component";
import { AccountInputDetailPageComponent } from "./pages/account-input-detail-page/account-input-detail-page.component";
import { AccountInputListPageComponent } from "./pages/account-input-list-page/account-input-list-page.component";
import { ApprovalsDetailPageComponent } from "./pages/approvals-detail-page/approvals-detail-page.component";
import { ApprovalsListPageComponent } from "./pages/approvals-list-page/approvals-list-page.component";
import { LoginFailedComponent } from "./pages/auth/login-failed/login-failed.component";
import { ClientsComponent } from "./pages/clients/clients.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { IllustrationInputDetailPageComponent } from "./pages/illustration-input-detail-page/illustration-input-detail-page.component";
import { IllustrationInputListPageComponent } from "./pages/illustration-input-list-page/illustration-input-list-page.component";

import { MessagesComponent } from "./pages/messages/messages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { OverviewComponent } from "./pages/overview/overview.component";
import { PaymentsDetailPageComponent } from "./pages/payments-detail-page/payments-detail-page.component";
import { PaymentsListPageComponent } from "./pages/payments-list-page/payments-list-page.component";
import { PoolingPermissionsDetailPageComponent } from "./pages/pooling-permissions-detail-page/pooling-permissions-detail-page.component";
import { PoolingPermissionsListPageComponent } from "./pages/pooling-permissions-list-page/pooling-permissions-list-page.component";
import { RequestIllustrationComponent } from "./pages/request-illustration/request-illustration.component";
import { ServiceFeesDetailPageComponent } from "./pages/service-fees-detail-page/service-fees-detail-page.component";
import { ServiceFeesListPageComponent } from "./pages/service-fees-list-page/service-fees-list-page.component";
import { ReportsComponent } from "./pages/Reports/reports.component";
import { SupportingFilesComponent } from "./pages/supporting-files/supporting-files.component";
import { UnauthorizedComponent } from "./pages/unauthorized/unauthorized.component";
import { RoleGuardService } from "../api-authorization/role-guard.service";
import { EsignatureComponent } from "./pages/e-signature/esignature.component";
import { FinalizedAccountsListPageComponent } from "./pages/finalized-accounts-list-page/finalized-accounts-list-page.component";
import { FinalizedAccountsDetailPageComponent } from "./pages/finalized-accounts-detail-page/finalized-accounts-detail-page.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";

export const routes: Routes = [
  { path: "", component: OverviewComponent, canActivate: [MsalGuard] },
  {
    // Needed for hash routing
    path: "error",
    component: OverviewComponent,
  },
  {
    // Needed for hash routing
    path: "state",
    component: OverviewComponent,
  },
  {
    // Needed for hash routing
    path: "code",
    component: OverviewComponent,
  },
  {
    path: "login-failed",
    component: LoginFailedComponent,
  },
  {
    path: "clients",
    component: ClientsComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ['Collaborator', 'NetworkMember']
    }
  },
  { path: "faq", component: FaqComponent, canActivate: [MsalGuard] },
  {
    path: "service-fees",
    component: ServiceFeesListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "service-fees/:id",
    component: ServiceFeesDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "payments",
    component: PaymentsListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "payments-mu",
    component: PaymentsListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "payments/:id",
    component: PaymentsDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "payments-mu/:id",
    component: PaymentsDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "pooling-permission",
    component: PoolingPermissionsListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "pooling-permission/:id",
    component: PoolingPermissionsDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "illustration-input",
    component: IllustrationInputListPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "illustration-input/:id",
    component: IllustrationInputDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "account-input",
    component: AccountInputListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "account-input/:id",
    component: AccountInputDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "account-input/:id/:clientId",
    component: AccountInputDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  { path: "messages", component: MessagesComponent, canActivate: [MsalGuard] },
  {
    path: "all-illustrations",
    component: IllustrationListComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "illustration/:id",
    component: IllustrationDetailComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "illustration-request",
    component: RequestIllustrationComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "reports/:reportName",
    component: ReportsComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "supporting-files",
    component: SupportingFilesComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  { path: "wiki", component: HomeComponent, canActivate: [MsalGuard] },
  {
    path: "file-upload/:process/:id",
    component: FileUploadTaskDetailComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "boolean-task/:process/:id",
    component: BooleanTaskDetailComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "approval",
    component: ApprovalsListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "approval/:id",
    component: ApprovalsDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "approval-mu",
    component: ApprovalsListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "approval-mu/:id",
    component: ApprovalsDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "e-sign",
    component: EsignatureComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "finalized-accounts",
    component: FinalizedAccountsListPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "annual-report/:id",
    component: FinalizedAccountsDetailPageComponent,
    canActivate: [MsalGuard, RoleGuardService],
    data: {
      authorizedRoles: ["Collaborator", "NetworkMember"],
    },
  },
  {
    path: "terms-and-conditions",
    component: TermsAndConditionsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent
  },
  { path: "404", component: NotFoundComponent, canActivate: [MsalGuard] },
  { path: "**", component: NotFoundComponent, canActivate: [MsalGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      // Don't perform initial navigation in iframes or popups
      /*initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabled'
          : 'disabled',*/
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
