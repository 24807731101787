
<section class="filter-labelled">
    <label for="yearId">Due date</label>
    <div class="d-flex filter-box custom-filter">
        <div class="input-group">
        <input
        (ngModelChange)="changeDate($event)"
        [(ngModel)]="dateInput" placeholder="Due date" autocomplete="off" class="form-control" name="date" bsDatepicker 
        [bsConfig]="{ minMode:'year', adaptivePosition: true, dateInputFormat: 'YYYY', minViewMode: 'years' }"
        #yearField
        readonly
    />
    <button type="button" class="btn-close ms-1 mt-2" aria-label="Close" (click)="clearSearch()" *ngIf="yearField.value">
        <span aria-hidden="true"></span>
      </button>
    </div>
  
 </div>
</section>