<div class="cookie-message" *ngIf="!acceptedCookies">
    <!--<div class="cookie-title">
        <a>Veuillez accepter l'utilisation des cookies :</a>
    </div>-->
    <div class="cookie-desc">
        <p>By clicking on Accept, you agree to our privacy policy and our use of cookies to enhance your experience 
            on our website. To learn more about how we use cookies and how you can manage them, 
            please refer to our cookie policy. </p>
     </div>
    <button class="cookie-button" (click)="acceptCookies()">Accept</button>
</div>

