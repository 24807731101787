<div style="float: right; margin-left: 1em;">
  <label class="switch colored">
    <fa-icon [icon]="faTh"></fa-icon>
    <input type="checkbox" (click)="changeIsTableViewValue(!this.isTableView)" [(ngModel)]="isTableView">
    <span class="slider"></span>
    <fa-icon [icon]="faBars"></fa-icon>
  </label>
</div>

<app-file-upload-task-list [headers]="headers" [title]="'Account-input'" [process]="process"
  [displayFilter]="true" [statusFilter]="null"
  noResultText="There are no account inputs" [tableView]="isTableView"></app-file-upload-task-list>