import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { PayToEnum } from 'src/app/web-api-client';

@Component({
  selector: 'app-payment-filter',
  templateUrl: './payment-filter.component.html',
  styleUrls: ['./payment-filter.component.scss']
})
export class PaymentFilterComponent implements OnInit {
  
  optionsPay: PayToEnum[] = [null, PayToEnum.Insurope, PayToEnum.Client, PayToEnum.Other];
  filteredOptions:PayToEnum []= this.optionsPay;
  selectedPayTo: PayToEnum = null; 

  @Input() networkMemberId: string = "";
  @Input() inOverview: boolean = false;
  @ViewChild('searchField', { static: true }) searchInputField: ElementRef;
  @Output() subscriberId = new EventEmitter<string>();
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService : CommonService) { }

  ngOnInit(): void {
    var storagePayTo = localStorage.getItem("payTo");
    if (storagePayTo == "null") {
      this.selectedPayTo = null;
    } else {
      this.selectedPayTo = parseInt(localStorage.getItem("payTo")) //parseInt(storagePayTo, 10);
    }
  }

  selectPayTo(event) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { payTo: event, },
        queryParamsHandling: 'merge', 
      });
    this.selectedPayTo = event;  
    this.filteredOptions = this.optionsPay;
    localStorage.setItem("payTo", event);
    //this.subscriberId.emit(event.toString());
  }

}
