<div style="float: right;">
  <label class="switch colored">
    <fa-icon [icon]="faTh"></fa-icon>
    <input type="checkbox" (click)="changeIsTableViewValue(!this.isTableView)"  [(ngModel)]="isTableView">
    <span class="slider"></span>
    <fa-icon [icon]="faBars"></fa-icon>
  </label>
</div>
<section class="sub-header">
  <button class="btn btn-back" (click)="location.back()">Back</button>
  <h2>All illustrations</h2> <button *ngIf="onOverview" id="illustrations-view-btn"
    class="btn btn-primary btn-page align-self-end" [routerLink]="'/all-illustrations'"
    [queryParams]="isOnClientView ? {'networkMemberId': networkmember?.id, 'clientId': networkmember?.externalAccountNumber} : null">
    View all
  </button>

</section>

<app-multiple-filter-search *ngIf="displayFilter" [isOnIllustration]="true"></app-multiple-filter-search>


<div *ngIf="!isTableView" class="card-table">
  <a *ngFor="let illustration of illustrations" class="card" [routerLink]="['/illustration', illustration.id]">
    <div class="card-body">
      <h3>
        {{ illustration.clientName }}
        <span *ngIf="illustration.countries[0]?.name">-</span>
        {{ illustration.countries[0]?.name | titlecase }}
      </h3>
      <h4>{{ illustration.illustrationRevisions[0]?.finalizedSupportDate | date }}</h4>
      <h4 style="color: lightgray">
        {{ illustration.networkMember?.accountName }}
      </h4>
    </div>

    <div class="card-footer">
      <p class="status {{ illustration.illustrationStage.name | lowercase }}">
        {{ illustration.illustrationStage.name | replace :'-':' ' }}
      </p>
    </div>
  </a>
</div>

<!--Table view-->
<section *ngIf="isTableView" class="card mt-3 mb-3 task-list">
  <div class="card-body">
    <table class="table table-borderless" id="excel-table">
      <thead>
        <tr class="table-head">
          <th>Client</th>
          <th>Network Member</th>
          <th>Country</th>
          <th>Poolable</th>
          <th>Finalized Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of illustrations" [routerLink]="['/illustration', item.id]">
          <td style="color: #4BBBC5;">{{ item.clientName }}</td>
          <td>{{ item.networkMember?.accountName }}</td>
          <td *ngIf="item.countries[0]?.name">{{ item.countries[0]?.name | titlecase}}</td>
          <td *ngIf="!item.countries[0]?.name">{{"."}}</td>
          <td>{{ item.illustrationRevisions[0]?.poolable? 'Yes' : 'No' }}</td>
          <td>{{ item.illustrationRevisions[0]?.finalizedSupportDate | date }}</td>
          <td class="status {{ item.illustrationStage.name | lowercase }}"> {{item.illustrationStage.name | replace :'-':' '}} </td>
         
        </tr>
      </tbody>
    </table>
  </div>
</section>

<div *ngIf="illustrations.length == 0 && !loadingIllustrations">There are no illustrations</div>

<div class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn" (click)="loadMore()">
    <span *ngIf="loadingIllustrations">Loading ...</span>
    <span *ngIf="!loadingIllustrations">Load more</span>
  </button>
</div>

<div *ngIf="loadingIllustrations && onOverview">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>