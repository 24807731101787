import { Component, OnInit } from '@angular/core';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { UserService } from 'src/app/services/user.service';
import {  UserDto } from 'src/app/web-api-client';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  faBell = faBell;
  faEnvelope = faEnvelope;
  faUser = faUser;
  userName : string;
  user:UserDto;
  constructor(public userService : UserService) { }

  async ngOnInit(){
    this.userName = await this.userService.getCurrentUserName();
  }
}
