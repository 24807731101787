import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { GeneralTaskDto } from 'src/app/web-api-client';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';
import { UserRoles } from 'src/app/models/enums/user-roles';

@Component({
  selector: 'app-payments-list-page',
  templateUrl: './payments-list-page.component.html',
  styleUrls: ['./payments-list-page.component.scss']
})
export class PaymentsListPageComponent implements OnInit {
  process = "payments"
  items: GeneralTaskDto[]
  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isCollaborator: boolean;
  isTableView: boolean = false;
  //headers:string[]=["Client","Network Member","Due date","Status", "Payment"]
  headers: { head: string, property: string }[] = [{ head: "Client", property: "title" }, { head: "Network Member", property: "networkMember?.accountName" }, { head: "Due date", property: "overdueDate" }, { head: "Status", property: "status.value" }]
  isTableViewStoredValueKey: string;
  statusFilter: number;
  faTh = faTh;
  faBars = faBars;
  constructor(private userService: UserService,
    private titleService: Title) { }

  async ngOnInit() {
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Payments | Insurope");
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))

    this.changeIsTableViewValue(storedValue);
    this.changeIsMultipoolViewValue();
  }

  changeIsTableViewValue(value) {
    if (value == null) {
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    } else {
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }

  changeIsMultipoolViewValue() {
    var isMultipoolViewValue = JSON.parse(localStorage.getItem("isPaymentMultipoolView"));

    if (isMultipoolViewValue == null) {
      isMultipoolViewValue = this.isTableView;
    }

    localStorage.setItem("isPaymentMultipoolView", JSON.stringify(isMultipoolViewValue));
  }
}
