<div id="myModal" class="modal">
    <div class="modal-content">
        <h2>Terms and Conditions</h2>

        <p><a href="https://insuropestoragedev.blob.core.windows.net/publiccontainer/privacy-notice/Privacy notice.pdf" target="_blank">Privacy notice</a></p>
        <p><a href="https://insuropestoragedev.blob.core.windows.net/publiccontainer/terms-and-conditions/Terms and Conditions.pdf" target="_blank">Terms & Conditions</a></p>
        
        <label><input type="checkbox" id="acceptCheckbox"><b>I accept the terms and conditions</b></label>
    <br>
        <button id="acceptBtn" type="button" class="btn btn-primary mb-3" disabled style="display: inline; float:left;" (click)="acceptTermsAndCondtions()">Accept</button>
        <button type="button" class="btn btn-danger mb-3" style="display: inline; float:right;"(click)="refuseTermsAndCondtions()">Cancel</button>
    </div>
</div>