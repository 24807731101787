import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'], 
  standalone: true, 
  imports:[NgxDocViewerModule]
})
export class ModalComponent implements OnInit {

  loading: boolean = true;
  @Input() title: string;
  url: string;

  constructor(public activeModal: NgbActiveModal ) { }

  async ngOnInit(): Promise<void> {

    this.loading = true;
  }
  
}
