import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { IdTokenClaims } from '@azure/msal-common';
import { Consent, UpdateUserConsentsCommand, UserDto, UsersClient } from '../web-api-client';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  isUnauthorized: boolean = false;
  loadedUser: boolean = false;

  constructor(private userController: MsalService, private userClient: UsersClient) {
  }

  async getCurrentUser(): Promise<UserDto> {
    var localStorageUser = localStorage.getItem('user');
    if (localStorageUser && (!localStorageUser.includes("termsAndConditions") || !localStorageUser.includes("privacyNotices"))) {
      localStorage.removeItem("user");
      localStorageUser = null;
    }

    var user = JSON.parse(localStorageUser) as UserDto;
    if (user && user.email.toLowerCase() != this.getCurrentUserEmail()) {
      user = null;
    }

    if (!user) {
      try {
        user = await (this.userClient.getCurrentUser().toPromise());
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
          this.loadedUser = true;
        }
      }
      catch (error) {
        this.isUnauthorized = true;
        this.loadedUser = true;
      }
    }
    else {
      this.loadedUser = true;
    }

    return user;
  }


  getCurrentUserEmail(): string {
    var token = this.userController.instance.getActiveAccount()?.idTokenClaims as IdTokenClaims
    return token?.emails[0]?.toLowerCase();
  }

  async getCurrentUserName() {
    var user = await this.getCurrentUser();
    return user?.firstName + " " + user?.lastName;
  }

  async getCurrentUserId() {
    var user = await this.getCurrentUser();
    return user.id;
  }

  async isUserInRole(role: string) {
    var user = await this.getCurrentUser();
    if (user) {
      return (user.roles.findIndex(c => c == role) > -1);
    }
    return false;
  }

  async isFinancial() {
    var user = await this.getCurrentUser();
    if (user) {
      return (user.isFinancial)
    }
  }

  async logout() {
    localStorage.setItem('user', null);
    this.userController.logoutRedirect({ account: this.userController.instance.getActiveAccount() });
  }

  async getAllUsersByNetworkMemberId(networkMemberId: string) {
    return await this.userClient.getUsersByNetworkMemberId(networkMemberId).toPromise();
  }

  async getAllUsersByConnectedNetworkMemberId() {
    return await this.userClient.getUsersByConnectedNetworkMemberId().toPromise();
  }

  async getUserById(id: string) {
    return await this.userClient.getUserById(id).toPromise();
  }

  async getAllUsers() {
    return await this.userClient.getAllUsers().toPromise();
  }

  async getPaginatedUsers(pageNumber, pageSize) {
    return await this.userClient.getUsers(pageNumber, pageSize).toPromise();
  }

  async getUserByIdByNM(id: string, nmIds: string[]) {
    return await this.userClient.getUserByIdByNM(id, nmIds).toPromise();
  }

  async logUserActivity(userAction: string, userId: string, email: string, path: string) {
    return await this.userClient.logUserActivity(userAction, userId, email, path).toPromise();
  }

  async getAllSubscriber(email: string) {
    return await this.userClient.getAllSubscribeUsers(email).toPromise();
  }

  async upsertUser(userId: string, privacyNotices: Consent, TermsAndConditions: Consent) {
    var updateConsentCommand = new UpdateUserConsentsCommand();

    updateConsentCommand.userId = userId;
    updateConsentCommand.privacyNotice = privacyNotices;
    updateConsentCommand.termsAndConditions = TermsAndConditions;

    return await this.userClient.updateUserConsents(updateConsentCommand).toPromise();
  }
} 
