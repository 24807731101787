
<a class="card" routerLink="/{{item?.processId}}/{{item?.id}}">
  <div class="card-body">
    <h3 class="{{ (dateTime.getDate() <= item?.displayDate.getDate() + 12) ? 'noOverdue' : (dateTime.getDate() >= item?.displayDate.getDate() + 13) &&  (dateTime.getDate() <= item?.displayDate.getDate() + 30) ? 'midOverdue' : 'overdue' }}">{{item?.title}}</h3>
    <h4 *ngIf="displaySubTitle">{{item?.subTitle}}</h4>
    <h4 *ngIf="displayNetworkMember" class="network-member">{{item?.networkMember?.accountName}}</h4>
  </div>
  <div class="card-footer">
   
    <p>Due: {{item?.displayDate | date}}</p>
    <p class="status {{status | lowercase}}">{{status}}</p>
    <!--if internal j'affiche le networkmember-->
  </div>
</a>
