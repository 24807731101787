<section class="faq-categories mb-3" *ngIf="faqCategories.length > 0">
  <ul>
    <li *ngFor="let faqCategory of faqCategories">
      <button class="tab" (click)="getCategoryID(faqCategory)">{{ faqCategory.name }}</button>
      <button *ngIf="isContentEditor" class="btn btn-primary ml-2 btn-admin-edit" (click)="openCat(faqCategory)">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill"
          viewBox="0 0 16 16">
          <path
            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
        </svg>
      </button>
    </li>
    <!-- Lors de l'event click, s'opère la méthode getCategoryID avec le paramète faqCategory.id correspondant au faqCategory.name -->
  </ul>
  
  <div class="add-category">
    <input *ngIf="isContentEditor" type="text" id="form12" class="form-control" [(ngModel)]="newCategory" placeholder="Type here the name of your new category"/>
    <button *ngIf="isContentEditor" (click)="addFaqCategory()" type="button" class="btn btn-primary ml-2" [disabled]="!newCategory">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
      </svg>
    </button>
  </div>
</section>

<section class="faq-box accordion card" id="accordionFAQ" *ngIf="faqCategories.length > 0">
  <ul class="card-body">
    <li *ngFor="let question of questions" class="accordion-item">
      <button class="btn btn-accordion collapse" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + question.id" aria-expanded="false" 
      [attr.aria-controls]="'#collapse' + question.id">
        {{ question.title }}
        <span class="chevron">
          <fa-icon [icon]="faChevronDown"></fa-icon>
        </span>
      </button>

      <div [attr.id]="'collapse' + question.id" class="collapse" data-parent="#accordionFAQ">
        <div>{{ question.answer }}</div>
        <button *ngIf="isContentEditor" class="btn btn-primary ml-2" (click)="openQuestion(question)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill"
            viewBox="0 0 16 16">
            <path
              d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
        </button>
      </div>
    </li>
    <input *ngIf="isContentEditor" type="text" placeholder="Type here the title of your new question" id="form12" class="form-control mb-2" [(ngModel)]="newQuestionTitle"  />
    <input *ngIf="isContentEditor" type="text" placeholder="Type here your new answer" id="form12" class="form-control mb-2" [(ngModel)]="newQuestionAnswer" />
    <button *ngIf="isContentEditor" (click)="addQuestion()" type="button" class="btn btn-primary" [disabled]="!(newQuestionTitle && newQuestionAnswer)">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
      </svg>
    </button>  
  </ul>
</section>

<section class="mt-3" *ngIf="faqCategories.length == 0">

  <h4>There are no FAQ categories yet.</h4>

</section>