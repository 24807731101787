import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';
import { UserRoles } from 'src/app/models/enums/user-roles';

@Component({
  selector: 'app-illustration-input-list-page',
  templateUrl: './illustration-input-list-page.component.html',
  styleUrls: ['./illustration-input-list-page.component.scss']
})
export class IllustrationInputListPageComponent implements OnInit {
  isTableView: boolean = false;
  headers: { head: string, property: string }[] = [{ head: "Client", property: "title" }, { head: "Network Member", property: "networkMember?.accountName" }, { head: "Country", property: "country" }, { head: "Status", property: "status.value" }]
  // hold the process id
  process = "illustration-input"
  isTableViewStoredValueKey: string;
  // determine if the user is a collaborator
  isCollaborator: boolean;
  statusFilter: number;
  faTh = faTh;
  faBars = faBars;

  // constructor
  constructor(private userService: UserService,
    private titleService: Title) { }

  // oninit method
  async ngOnInit() {

    // determine if the user is a collaborator
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    // set the title
    this.titleService.setTitle("Illustration-input | Insurope");
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'
    this.statusFilter = this.isCollaborator ? null : 0;

    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
    this.changeIsTableViewValue(storedValue);
  }

  changeIsTableViewValue(value) {
    if (value == null) {
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    } else {
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
}