import { Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Sort } from './Sort';



@Directive({
  selector: '[appSort]'
})
export class SortDirective {

  @Input() appSort: {list:any[],prop:string};
  constructor(private renderer: Renderer2, private targetElem: ElementRef) { }
  @Input() property:string

  @HostListener("click")
  sortData() {
    // Create Object of Sort Class
    const sort = new Sort();
    // Get Reference Of Current Clicked Element
    const elem = this.targetElem.nativeElement;
    // Get In WHich Order list should be sorted by default it should be set to desc on element attribute
    const order = elem.getAttribute("data-order");
    // Get The Property Type specially set [data-type=date] if it is date field
    const type = elem.getAttribute("data-type");
    // Get The Property Name from Element Attribute
    const property = this.appSort.prop;
    console.log(property);
    
    if (order === "desc") {
      this.appSort.list.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "asc");
    }
    else {
      this.appSort.list.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "desc");
    }
  }
}