<header class="dark-background">
  <div class="say-hello">
    <h1>Hello<span> {{userName}}</span></h1>
  </div>
  <div class="corner-menu">
    <!-- <button class="btn btn-light notification">
      <span class="amount">8</span>
      <fa-icon [icon]="faBell"></fa-icon>
    </button>
    <button class="btn btn-light message">
      <span class="amount">8</span>
      <fa-icon [icon]="faEnvelope"></fa-icon>
    </button> -->
    <!-- <button class="btn btn-light profile">
      <fa-icon [icon]="faUser"></fa-icon>
      <span>My profile</span>
    </button> -->

    <button (click)="userService.logout()" class="btn btn-light logout">
      <!-- <fa-icon [icon]="faUser"></fa-icon> -->
      <span>Log out</span>
    </button>
  </div>
  <!-- <fa-icon [icon]="faCoffee"></fa-icon> -->
</header>
