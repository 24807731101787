import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private modalService: NgbModal, public documentService: DocumentService) { }

  ngOnInit(): void {
  }

  openModal(type: string, title: string) {
    let url = '';
    if (type === 'privacy') {
      url = 'https://insuropestoragedev.blob.core.windows.net/publiccontainer/privacy-notice/Privacy notice.pdf';
    } else if (type === 'terms') {
      url = 'https://insuropestoragedev.blob.core.windows.net/publiccontainer/terms-and-conditions/Terms and Conditions.pdf';
    }
    const modalRef = this.modalService.open(ModalComponent, { size: 'lg' });
    modalRef.componentInstance.url = url;
    modalRef.componentInstance.title = title;
  }
}
