
import { UserService } from 'src/app/services/user.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDto } from 'src/app/web-api-client';
import { FormControl, FormGroup } from '@angular/forms';
import { UserRoles } from 'src/app/models/enums/user-roles';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map } from 'rxjs';

@Component({
  selector: 'app-users-search',
  templateUrl: './users-search.component.html',
  styleUrls: ['./users-search.component.scss']
})

export class UsersSearchComponent implements OnInit {
  // search indicators
  searchingUsers: boolean = false;
  filteredUsers: UserDto[] = [];
  clickedOnUser: boolean = false;
  users: UserDto[] = [];
  isInternal: boolean = false;
  inputGroup: FormGroup;

  @Input() networkMemberId: string = "";
  @Input() inOverview: boolean = false;
  @ViewChild('searchField', { static: true }) searchInputField: ElementRef;
  @Output() subscriberId = new EventEmitter<{ id: string, user: UserDto }>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public userService: UserService) { }

  async ngOnInit() {
    this.inputGroup = new FormGroup({ search: new FormControl() });

    let selectedUserId = localStorage.getItem('subscriberId');
    if (selectedUserId) {
      var user = await this.userService.getUserById(selectedUserId);
      this.searchInputField.nativeElement.value = user.preferredEmail;
    }

    this.isInternal = await this.userService.isUserInRole(UserRoles.Collaborator);

    if (this.isInternal) {
      if (this.networkMemberId) {
        this.users = await this.userService.getAllUsersByNetworkMemberId(this.networkMemberId);
      }
    }
    else {
      this.users = await this.userService.getAllUsersByConnectedNetworkMemberId();
    }


    fromEvent(this.searchInputField.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater than 1
      filter(res => res.length >= 3),
      // Time in milliseconds between key events
      debounceTime(50),
      // If previous query is diffent from current   
      distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.clickedOnUser = false;
      this.fetchUser(text);
    });

    fromEvent(this.searchInputField.nativeElement, 'keydown').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      filter(res => res.length < 1),
    ).subscribe((text: string) => {
      this.clearSearch();
    });
  }

  async fetchUser(term: string) {
    if (term === '') {
      this.filteredUsers = [];
    }

    this.searchingUsers = true;

    if (this.isInternal && !this.networkMemberId) {
      this.filteredUsers = await this.userService.getAllSubscriber(term)
    }
    else {
      this.filteredUsers = this.users.filter((val) =>
        val.preferredEmail.toLowerCase().includes(term.toLowerCase())
      );
    }

    this.searchingUsers = false;
  }

  async selectUser(id: string) {
    let user: UserDto = null;
    user = this.filteredUsers.find(x => x.id == id);

    // if (!this.isInternal) {
    //   let currentUser = (await this.userService.getCurrentUser()).networkMemberIds
    //   user = await this.userService.getUserByIdByNM(id, currentUser);
    // } else {
    //   user = await this.userService.getUserById(id);
    // }

    this.searchInputField.nativeElement.value = user.preferredEmail;
    localStorage.setItem("subscriberId", user.id);

    this.addSubscriber(id, user);
    this.filteredUsers = [];
  }

  async clearSearch() {
    this.filteredUsers = [];
    localStorage.removeItem('subscriberId');

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { subscriberId: null },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.filteredUsers = [];
    this.searchInputField.nativeElement.value = "";
  }

  addSubscriber(id: string, user: UserDto) {

    if (this.inOverview) {
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: { subscriberId: id, },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    } else {
      this.subscriberId.emit({ id, user });
    }
  }
}

