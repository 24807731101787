import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {

  // input parameters
  @Input() title: string;
  @Input() message: string;
  @Input() type: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() dismissable: boolean = false;

  // output parameters
  @Output() declineEmitter = new EventEmitter();
  @Output() acceptEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();

  // constructor
  constructor() { }

  // oninit method
  ngOnInit(): void {
  }

  // decline method
  decline() {
    this.declineEmitter.emit();
  }

  // accept method
  accept() {
    this.acceptEmitter.emit();
  }

  // close method
  close() {
    this.closeEmitter.emit();
  }

}
