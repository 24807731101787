<section class="sub-header pr-2">
  <button class="btn btn-back" (click)="location.back()">Back</button>
  <h2>{{ title }}</h2> <button *ngIf="onOverview" class="btn btn-primary btn-page align-self-end" [routerLink]="'/'+process" [queryParams]="isOnClientView ? {'networkMemberId': networkMemberId, 'clientId' : params?.get('clientId')}: null">
    View all
  </button>
  <span class="ms-auto">
    <p>{{lastRefreshDate}}</p>
  </span>
  <label class="switch colored">
    <span>SA</span>
    <input type="checkbox" (click)="changeIsMuView(!this.isMuView)" [(ngModel)]="isMuView">
    <span class="slider"></span>
    <span>MU</span>
  </label>
</section>
<app-multiple-filter-search *ngIf="displayFilter"></app-multiple-filter-search>

<div class="card-table" *ngIf="!tableView">
  <a *ngFor="let item of items" class="card" routerLink="/{{item?.processId}}/{{ item?.id }}">
    <div class="card-body">
      <h3 class="{{ (dateTime.getDate() < item?.displayDate.getDate() + 3) ? '' : (dateTime.getDate() > item?.displayDate.getDate() + 3) &&  (dateTime.getDate() < item?.displayDate.getDate() + 10) ? 'midOverdue' : 'overdue' }}">
        {{ item?.title }} {{ getMetaData(item) ? ' - ' + getMetaData(item) : '' }}</h3>
      <h4>{{ item.subTitle }}</h4>
      <h4 *ngIf="!isNetworkMember" class="network-member">
        {{ item?.networkMember?.accountName }}
      </h4>
      <h4>Due date : {{ getDueDate(item)}}</h4>
    </div>
    <div class="card-footer">
      <p class="{{ 'text-success' }}">{{item.value==true ? "Approved" :""}}</p>
      <p class="status {{ commonService.getTaskStatusByNumber(item?.status.value,true) | lowercase }}">
        {{commonService.getTaskStatusByNumber(item?.status.value,true)}}
      </p>
    </div>
  </a>
</div>

<!--Table view-->
<section *ngIf="tableView" class="card mt-3 mb-3 task-list">
  <div class="card-body">
    <table class="table table-borderless" id="excel-table">
      <thead>
        <tr class="table-head">
          <th  *ngFor="let head of headers" [appSort]="{list:items,prop:head.property}" [property]="head.property" data-order="desc" >{{head.head}}</th>
          <th [appSort]="{list:items,prop:'value'}" data-order="desc" data-name="value">NM approved</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items" routerLink="/{{item?.processId}}/{{ item?.id }}">
          <td class="{{ item.isOverdue == false ? '' : 'overdue' }}">
            {{ item?.title }} {{ getMetaData(item) ? ' - ' + getMetaData(item) : '' }}</td>
          <td *ngIf="!isNetworkMember" class="network-member">
            {{ item?.networkMember?.accountName }}
          </td>
          <td>{{getApprovalDate(item)}}</td>
          <td>{{ getDueDate(item)}}</td>
          <td><span class="status {{ commonService.getTaskStatusByNumber(item?.status.value,true) | lowercase }}">{{
              commonService.getTaskStatusByNumber(item?.status.value,true) }}</span></td>
          <td>
            <span class="{{ 'text-success' }}">{{item.value==true ? "Approved" : ""}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<div *ngIf="items?.length == 0 && !loadingBooleanTask">There are no outstanding tasks.</div>

<div class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn" (click)="loadMore()">
    <span *ngIf="loadingBooleanTask">Loading ...</span>
    <span *ngIf="!loadingBooleanTask">Load more</span>
  </button>
</div>

<div *ngIf="loadingBooleanTask && onOverview">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>