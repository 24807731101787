<app-boolean-task-detail *ngIf="!loadingTask"></app-boolean-task-detail>

<!-- Client details table -->
<section class="card mb-3 approval-list" *ngIf="!loadingTask && approvalQuestions.length > 0">
  <div class="card-body">
    <div style="display: inline-block; height: 100%;
    width: 100%;" (click)="collapse.toggle()" [attr.aria-expanded]="!gfg" aria-controls="table">
      <div class="expand"><fa-icon [icon]="this.gfg ? this.faChevronDown : this.faChevronUp"></fa-icon></div>
    </div>
    <button type="button" class="btn btn-primary mb-3" style="display: inline; float:left;" (click)="exportexcel()">Export to excel</button>
    <table class="table table-borderless" id="excel-table" #collapse="ngbCollapse" [(ngbCollapse)]="gfg">
      <thead>
        <tr class="table-head">
          <th>Country</th>
          <th>Subsidiary Label</th>
          <th>Client name</th>
          <th>Approval Letter</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let approvalQuestion of approvalQuestions">
          <td data-label="Country">
            {{approvalQuestion.CountryName}}
          </td>
          <td data-label="Subsidiary Label">{{ approvalQuestion.SubsidiaryLabel }}</td>
          <td data-label="Client Name">{{ approvalQuestion.ClientName }}</td>
          <td data-label="Approval Letter">
            <p>{{ approvalQuestion.ExternalPoolComment }} </p><br>
            <p style="white-space: pre-line;" [innerHTML]="approvalQuestion.ApprovalLetter"></p>
          </td>
          <td *ngIf="isMuTask" data-label="Add Tag" style="width: min-content;text-align: -webkit-right;">
            <button type="button" class="btn btn-primary" (click)="ApproveDisapproveClient(approvalQuestion)"
              [disabled]="(approvalQuestion.ApprovedByNetworkMember && isNetworkMember) || (!approvalQuestion.ApprovedByNetworkMember && !isNetworkMember)">{{GetButtonName(approvalQuestion.ApprovedByNetworkMember)}}</button>
          </td>
          <td data-label="Add Tag" style="width: min-content;text-align: -webkit-right;">
            <button type="button" class="btn btn-primary" (click)="Reply(approvalQuestion.ClientName,approvalQuestion.ApprovalLetter)" *ngIf="isMuTask">Reply</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<!-- Load more & load all buttons -->
<div *ngIf="!loadingTask" class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn && processId=='approval'" (click)="loadMore()">
    <span>Load more</span>
  </button>
  <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn && processId=='approval-mu'" (click)="loadAll()">
    <span>Load All</span>
  </button>
</div>

<!-- Show the comment component-->
<!--TODO: Replace all the properties below by one single class-->
<app-comment-list *ngIf="!loadingTask" [taskId]="taskId" [networkMemberId]="networkMemberId" [networkMemberName]="task.networkMember?.accountName" [clientName]="this.task.title" [service]="booleanTaskService" [status]="status"
  [country]="this.approvalQuestions[0]?.CountryName" [isTagSystemApplicable]="true" [clientNameList]="clientNameList" [periodEnd]="periodEnd"></app-comment-list>

<app-loading-spinner *ngIf="loadingTask"></app-loading-spinner>