import { Component, OnInit } from '@angular/core';
import { EmbedParamsDTO, ReportingClient, UserDto } from 'src/app/web-api-client';
import { models, IReportEmbedConfiguration } from 'powerbi-client';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
  report: EmbedParamsDTO = null;
  firstReportUrl = "";
  customClass = "report-info"
  reportType = "report";
  reportConfig: IReportEmbedConfiguration;
  connectedUser: UserDto;

  constructor(private client: ReportingClient, private activatedroute: ActivatedRoute, private router: Router, private userService: UserService) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

  }

  async ngOnInit() {
    var reportName = this.activatedroute.snapshot.paramMap.get('reportName');
    this.connectedUser = await this.userService.getCurrentUser();
    await this.loadReport(reportName);
  }

  async loadReport(reportName: string) {
    this.report = await this.client.getToken(reportName).toPromise();
    this.firstReportUrl = this.report.embedReport[0].embedUrl;

    this.reportConfig = {
      type: "report",
      id: this.report.embedReport[0].reportId,
      embedUrl: this.firstReportUrl,
      accessToken: this.report.embedToken.token,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          }
        },
        hyperlinkClickBehavior: models.HyperlinkClickBehavior.NavigateAndRaiseEvent,
        background: models.BackgroundType.Transparent,
        customLayout: {
          displayOption: models.DisplayOption.FitToWidth
        }
      },
    };
  }
}
