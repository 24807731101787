import { Input } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { GeneralTaskDto } from 'src/app/web-api-client';


@Component({
  selector: 'app-informative-item',
  templateUrl: './informative-item.component.html',
  styleUrls: ['./informative-item.component.scss']
})
export class InformativeItemComponent implements OnInit {

  @Input() item: GeneralTaskDto;
  @Input() displayNetworkMember:boolean=false;
  @Input() status:string;
  @Input() displaySubTitle: boolean = true;
  dateTime = new Date()
  // constuctor
  constructor() { }

  // oninit method
  ngOnInit() { }

}
