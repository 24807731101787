import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlSegment } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../app/services/user.service';
import { PopupNotificationsService, MessageType } from '../app/services/popup-notifications/popup-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {
  isFinancial: boolean;

  constructor(private userService: UserService,
    private popupNotificationService: PopupNotificationsService, private router: Router) {
    this.checkUserRole();
  }

  async checkUserRole() {
    var user = (await this.userService.getCurrentUser());
    this.isFinancial = !user && user.isFinancial;
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const authorizedRoles = route.data.authorizedRoles;
    if (!this.userService.loadedUser)
      return false;

    if (this.userService.isUnauthorized) {
      this.router.navigate(["unauthorized"]);
      return false;
    }

    /* if (route.url[0].path == "service-fees" && ! this.isFinancial)
    {
      this.popupNotificationService.showMessage('You are not authorized to access this page.', MessageType.Warning);
      return false;
    } */
    
    if (!(await this.userService.getCurrentUser())?.roles) {
      this.popupNotificationService.showMessage("Your account doesn't have any role.", MessageType.Warning);
      return false;
    }

    let userHasRole = false;
    if (authorizedRoles) {
      for (let role of authorizedRoles) {
        userHasRole = await this.userService.isUserInRole(role);
        if (userHasRole) {
          break;
        }
      }
    }

    if (!userHasRole) {
      this.popupNotificationService.showMessage('You are not authorized to access this page.', MessageType.Warning);
      return false;
    }

    return true;
  }
}