<section class="sub-header">
  <!-- Show the back btn -->
  <button class="btn btn-back" (click)="location.back()">Back</button>
  <!-- Show the title -->
  <h2>Woops, something's missing</h2>
</section>

<div class="card redirection d-flex flex-column align-items-center">
  <h3>This page is missing or you assembled the link incorrectly.</h3>
  <a [routerLink]="['']" class="btn btn-third mt-4"><fa-icon [icon]="faEye" class="mr-2"></fa-icon>Overview</a>
</div>
