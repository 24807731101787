import { Component, OnInit } from '@angular/core';
import { EmbeddedEnvelope } from 'src/app/models/EmbeddedEnvelope';
import { ESignatureService } from 'src/app/services/e-signature/e-signature.service';
import { FileParameter } from 'src/app/web-api-client';

@Component({
  selector: 'app-esignature',
  templateUrl: './esignature.component.html',
  styleUrls: ['./esignature.component.scss']
})
export class EsignatureComponent implements OnInit {
  embeddedSigningUrl: string;
  uploadedFile:FileParameter;

  constructor(private eSignatureService: ESignatureService) { }

  ngOnInit(): void {
  }

  startSigning() {
    var embeddedEnvelopQuery = new EmbeddedEnvelope();
      embeddedEnvelopQuery.DocPdf= this.uploadedFile,
      embeddedEnvelopQuery.ReturnUrl= "";
      embeddedEnvelopQuery.SignerClientId= "";
      embeddedEnvelopQuery.SignerEmail= "";
      embeddedEnvelopQuery.SignerName= "";

    this.eSignatureService.getEmbeddedUrl(embeddedEnvelopQuery).then(url => this.embeddedSigningUrl = url);
  }

  fileUploaded(response: { isUploaded: boolean, message: string, files?: File[] }) {
    let fileParameter: FileParameter = { data: response.files[0], fileName: response.files[0].name };
    this.uploadedFile = fileParameter;

  }
}
