<section class="sub-header pr-2">
  <button class="btn btn-back" (click)="location.back()">Back</button>
  <h2>{{ title }}</h2> <button *ngIf="onOverview" class="btn btn-primary btn-page align-self-end"
    [routerLink]="'/'+process"
    [queryParams]="isOnClientView ? {'networkMemberId': networkMemberId, 'clientId' : params?.get('clientId')}: null">
    View all
  </button>
  <span class="ms-auto">
    <p>{{lastRefreshDate}}</p>
  </span>
  <label *ngIf="process !='service-fees'" class="switch colored">
    <span>SA</span>
    <input type="checkbox" (click)="changeIsMuView(!this.isMuView)" [(ngModel)]="isMuView">
    <span class="slider"></span>
    <span>MU</span>
  </label>
</section>

<app-multiple-filter-search [isPayments]="true" *ngIf="displayFilter"></app-multiple-filter-search>

<div class="card-table" *ngIf="!tableView">
  <a *ngFor="let item of items" class="card" routerLink="/{{item?.processId}}/{{item?.id}}/{{this.clientIdParam}}">
    <div class="card-body">
      <h3
        class="{{ (dateTime.getDate() <= item?.displayDate.getDate() + 12) ? 'noOverdue' : (dateTime.getDate() >= item?.displayDate.getDate() + 13) &&  (dateTime.getDate() <= item?.displayDate.getDate() + 30) ? 'midOverdue' : 'overdue' }}">
        {{item?.title}}</h3>
      <h4 *ngIf="displaySubTitle">{{item?.subTitle}}</h4>
      <td *ngIf="!isNetworkMember">
        <span *ngIf="!item?.networkMember?.accountName" class="network-member badge badge-danger">Network Member
          unknown</span>
        <h4 *ngIf="item?.networkMember?.accountName">{{item?.networkMember?.accountName}}</h4>
      </td>
      <h4 *ngIf="item?.payTo != 0">Payment to: {{ commonService.getPayToText(item?.payTo) }}</h4>
    </div>
    <div class="card-footer">

      <p class="status {{commonService.getTaskStatusByNumber(item?.status.value) | lowercase}}">
        {{commonService.getTaskStatusByNumber(item?.status.value)}}</p>
    </div>
  </a>
</div>

<!-- table view -->
<section *ngIf="tableView" class="card mt-3 mb-3 task-list">
  <div class="card-body">
    <table class="table table-borderless" id="excel-table">
      <thead>
        <tr class="table-head">
          <th  *ngFor="let head of headers" [appSort]="{list:items,prop:head.property}" [property]="head.property" data-order="desc" >{{head.head}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items" routerLink="/{{ item?.processId }}/{{ item?.id }}">
          <td
            class="{{ (dateTime.getDate() <= item?.displayDate.getDate() + 12) ? '' : (dateTime.getDate() >= item?.displayDate.getDate() + 13) &&  (dateTime.getDate() <= item?.displayDate.getDate() + 30) ? 'midOverdue' : 'overdue' }}">
            {{ item?.title }}</td>
          <td *ngIf="!isNetworkMember">{{item?.networkMember?.accountName}}</td>
          <td *ngIf="displaySubTitle">{{item?.subTitle }}</td>

          <td>{{item?.displayDate | date}}</td>
          <td><span class="status {{ commonService.getTaskStatusByNumber(item?.status.value) | lowercase }}">{{
              commonService.getTaskStatusByNumber(item?.status.value) }}</span></td>
          <td>{{ commonService.getPayToText(item?.payTo) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<div *ngIf="items?.length == 0 && !loadingInformativeTask">There are no outstanding tasks.</div>

<div class="d-flex justify-content-center">
  <button type="button" class="btn btn-primary mt-3" *ngIf="!onOverview && !hideShowMoreBtn" (click)="loadMore()">
    <span *ngIf="loadingInformativeTask">Loading ...</span>
    <span *ngIf="!loadingInformativeTask">Load more</span>
  </button>
</div>

<div *ngIf="loadingInformativeTask && onOverview">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>