import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-refresh-task-view',
  templateUrl: './refresh-task-view.component.html',
  styleUrls: ['./refresh-task-view.component.scss']
})
export class RefreshTaskViewComponent implements OnInit {
  @Input() cron: string;
  @Input() lastModified: string;

  dateModified: string;

  constructor(public datePipe: DatePipe) {
  }

  ngOnInit(): void {
    if(this.lastModified === undefined){
      this.dateModified ="";
    }else{
      this.dateModified = this.datePipe.transform(this.lastModified, "medium");
    }
    
  }
}
