import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export enum MessageType {
  Error,
  Success,
  Information,
  Warning,
}

@Injectable({
  providedIn: 'root'
})
export class PopupNotificationsService {

  constructor(private toastr: ToastrService) {}

  showMessage(
    message: string,
    type: MessageType,
    duration = 10000
  ): void {
    switch (type) {
      case MessageType.Error:
        this.toastr.error(message, "Error", {
          timeOut: duration
        });
        break;
      case MessageType.Warning:
        this.toastr.warning(message, "Warning", {
          timeOut: duration
        });
        break;
      case MessageType.Success:
        this.toastr.success(message, "Success", {
          timeOut: duration
        });
        break;
      default:
        this.toastr.info(message, "Info", {
          timeOut: duration
        });
        break;
    }
  }

  showServerErrorNotification(
    error: any
  ): void {
    switch (error.status) {
      case 0:
        this.showMessage('The connection to the server has failed, please contact insuropexchange@insurope.com if the error persists.', MessageType.Error);
        break;
      case 401:
        this.showMessage('An error occured on the authentication, please contact insuropexchange@insurope.com if the error persists.', MessageType.Error);
        break;
      case 400:
        this.showMessage('A validation error occured, please contact insuropexchange@insurope.com if the error persists.'.concat(error.message), MessageType.Error);
        break;
      case 404:
      case 504:
        this.showMessage(error.error.detail, MessageType.Error);
        break;
      case 403:
        this.showMessage('You are not authorized to perform this action, please contact insuropexchange@insurope.com if the error persists.', MessageType.Error);
        break;
      default:
        this.showMessage('An unexpected error occured, please contact insuropexchange@insurope.com if the error persists.', MessageType.Error);
    }
  }
}
