import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { BooleanTaskService } from 'src/app/services/boolean-task/boolean-task.service';
import { CommentService } from 'src/app/services/comment.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageType, PopupNotificationsService } from 'src/app/services/popup-notifications/popup-notifications.service';
import { Attachment, AttachmentDTO } from 'src/app/web-api-client';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss']
})
export class AttachmentListComponent implements OnInit {

  // take list of attachments as input
  @Input() attachments: Attachment[] = [];
  @Input() commentAttachments: AttachmentDTO[] = []
  @Input() isSystemAttachment: boolean = false;
  @Input() adminDbAttachments: string[];
  @Input() illustrationAttachments: string[];

  @Input() service: any;

  // take title as input
  @Input() title: string = "Files";

  // hold the task id
  taskId: string;

  // fa icons
  faDownload = faDownload;

  // display the loading indicator or not
  downloadingFile: boolean = false;

  // constructor
  constructor(private activatedroute: ActivatedRoute, private popupNotificationsService: PopupNotificationsService, private commentService: CommentService, public documentService: DocumentService, private booleanTaskService: BooleanTaskService) { }

  // oninit method
  ngOnInit(): void {
    // get the task id
    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
  }

  async downloadFileFromPath(filePath: string) {
    // downloading started
    this.downloadingFile = true;
    // get the download string
    this.service.downloadFileFromPath(this.taskId, filePath).then((downloadString) => {
      let fileName = filePath.split('/').pop();
      // download the file from the server
      let download = "data:application/octet-stream;base64," + downloadString;
      // download the file
      var a = document.createElement('a');
      a.setAttribute('href', download);
      a.setAttribute('download', fileName);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // downloading finished
      this.downloadingFile = false;
    }).catch((error: { status: number; }) => {
      // if the error is forbidden
      if (error.status == 403) {
        // show the error message
        this.popupNotificationsService.showMessage("You are not authorized to download this file, please contact insuropexchange@insurope.com", MessageType.Warning);
      }
      // if the error is not found
      else if (error.status == 404) {
        // show the error message
        this.popupNotificationsService.showMessage("File not found", MessageType.Error);
      }
      else {
        // show the error message
        this.popupNotificationsService.showMessage("An error occurred while downloading the file", MessageType.Error);
      }
      // downloading finished
      this.downloadingFile = false;
    });
  }

  // method to download the file
  async downloadFile(file: Attachment) {
    // downloading started
    this.downloadingFile = true;

    // get the download string
    this.service.downloadFile(this.taskId, file.id, this.isSystemAttachment).then((downloadString) => {

      // download the file from the server
      let download = "data:application/octet-stream;base64," + downloadString;
      // download the file
      var a = document.createElement('a');
      a.setAttribute('href', download);
      a.setAttribute('download', file.name);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // downloading finished
      this.downloadingFile = false;
    }).catch((error) => {
      // if the error is forbidden
      if (error.status == 403) {
        // show the error message
        this.popupNotificationsService.showMessage("You are not authorized to download this file, please contact insuropexchange@insurope.com", MessageType.Warning);
      }
      // if the error is not found
      else if (error.status == 404) {
        // show the error message
        this.popupNotificationsService.showMessage("File not found", MessageType.Error);
      }
      else {
        // show the error message
        this.popupNotificationsService.showMessage("An error occurred while downloading the file", MessageType.Error);
      }

      // downloading finished
      this.downloadingFile = false;
    });
  }

  // method to download the file
  async downloadCommentFile(file: AttachmentDTO) {
    this.downloadingFile = true;
    // download the file from the server
    let download = "data:application/octet-stream;base64," + await this.commentService.downloadFile(file.commentId, this.taskId, file.id);

    // download the file
    var a = document.createElement('a');
    a.setAttribute('href', download);
    a.setAttribute('download', file.name);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.downloadingFile = false;
  }

  async downloadAdminDbFile(path: string) {
    var fileType = path.split(".")[1];

    if (fileType != "docx" && fileType != "doc") {
      this.downloadFileFromPath(path);
      return;
    }

    this.downloadingFile = true;
    var file = await this.documentService.getAdminDbDocToPdf(path);
    const imageBlob = this.dataURItoBlob(file);
    var newPath = this.documentService.sanitizeFileName(path, ".pdf")
    var test = new File([imageBlob], newPath, { type: 'application/pdf' });
    FileSaver.saveAs(test)
    this.downloadingFile = false;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  formatdate(fileDate: Date) {

    if (fileDate.getTime() < new Date('Mon Jan 01 0001 00:17:30 GMT+0017').getTime()) {
      return '';
    }
    else {
      return fileDate?.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    }

  }
}
