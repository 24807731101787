import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { API_BASE_URL } from './web-api-client';
import { FileUploadButtonComponent } from './components/Inputs/file-upload-button/file-upload-button.component';
import { RequestIllustrationComponent } from './pages/request-illustration/request-illustration.component';
import { FileUploadTaskDetailComponent } from './components/tasks/file-upload-task/file-upload-task-detail/file-upload-task-detail.component';
import { FileUploadTaskListComponent } from './components/tasks/file-upload-task/file-upload-task-list/file-upload-task-list.component';
import { InformativeListComponent } from './components/tasks/informative-task/informative-list/informative-list.component';
import { InformativeDetailComponent } from './components/tasks/informative-task/informative-detail/informative-detail.component';
import { InformativeItemComponent } from './components/tasks/informative-task/informative-item/informative-item.component';
import { HeaderComponent } from './components/common/header/header.component';
import { NavMenuComponent } from './components/common/nav-menu/nav-menu.component';
import { CommentItemComponent } from './components/tasks/comment-task/comment-item/comment-item.component';
import { CommentListComponent } from './components/tasks/comment-task/comment-list/comment-list.component';
import { CommentDetailComponent } from './components/tasks/comment-task/comment-form/comment-form.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { IllustrationListComponent } from './components/illustrations/illustration-list/illustration-list.component';
import { IllustrationDetailComponent } from './components/illustrations/illustration-detail/illustration-detail.component';
import { BooleanTaskDetailComponent } from './components/tasks/boolean-task/boolean-task-detail/boolean-task-detail.component';
import { BooleanTaskListComponent } from './components/tasks/boolean-task/boolean-task-list/boolean-task-list.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientsDetailComponent } from './components/clients/clients-detail/clients-detail.component';
import { ClientsSearchComponent } from './components/common/filters/clients-search/clients-search.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import { NgxEditorModule } from 'ngx-editor';
import { QuillModule } from 'ngx-quill'
import { FileSaverModule } from 'ngx-filesaver';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CookieService } from 'ngx-cookie-service';
import { CookieConsentComponent } from './components/common/cookie-consent/cookie-consent.component';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';

import { b2cPolicies, apiConfig } from './b2c-config';
import { LoginFailedComponent } from './pages/auth/login-failed/login-failed.component';
import { ServiceFeesListPageComponent } from './pages/service-fees-list-page/service-fees-list-page.component';
import { PaymentsListPageComponent } from './pages/payments-list-page/payments-list-page.component';
import { ApprovalsListPageComponent } from './pages/approvals-list-page/approvals-list-page.component';
import { AccountInputListPageComponent } from './pages/account-input-list-page/account-input-list-page.component';
import { PoolingPermissionsListPageComponent } from './pages/pooling-permissions-list-page/pooling-permissions-list-page.component';
import { NetworkMemberFilterComponent } from './components/common/filters/network-member-filter/network-member-filter.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FaqCategoriesComponent } from './pages/faq/faq-categories/faq-categories.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from './components/common/confirmation-modal/confirmation-modal.component';
import { MessageBoxComponent } from './components/common/message-box/message-box.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ServiceFeesDetailPageComponent } from './pages/service-fees-detail-page/service-fees-detail-page.component';
import { PaymentsDetailPageComponent } from './pages/payments-detail-page/payments-detail-page.component';
import { PoolingPermissionsDetailPageComponent } from './pages/pooling-permissions-detail-page/pooling-permissions-detail-page.component';
import { AccountInputDetailPageComponent } from './pages/account-input-detail-page/account-input-detail-page.component';
import { ApprovalsDetailPageComponent } from './pages/approvals-detail-page/approvals-detail-page.component';
import { AttachmentListComponent } from './components/common/attachment-list/attachment-list.component';
import { ModalBasicComponent } from './components/common/modal-basic/modal-basic.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ErrorCatchingInterceptor } from './interceptors/error-catching.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { IllustrationInputDetailPageComponent } from './pages/illustration-input-detail-page/illustration-input-detail-page.component';
import { IllustrationInputListPageComponent } from './pages/illustration-input-list-page/illustration-input-list-page.component';
import { NgbdSortableHeaderDirective } from './components/clients/clients-detail/ngbd-sortable-header.directive';
import { RefreshTaskViewComponent } from './components/common/refresh-task-view/refresh-task-view.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { UsersSearchComponent } from './components/common/filters/users-search/users-search.component';
import { MultipleFilterSearchComponent } from './components/common/filters/multiple-filter-search/multiple-filter-search.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { ReportsComponent } from './pages/Reports/reports.component';
import { EsignatureComponent } from './pages/e-signature/esignature.component';
import { FinalizedAccountsDetailPageComponent } from './pages/finalized-accounts-detail-page/finalized-accounts-detail-page.component';
import { FinalizedAccountsListPageComponent } from './pages/finalized-accounts-list-page/finalized-accounts-list-page.component';
import { ReplacePipe } from './Common/ReplacePipe';
import { StatusFilterComponent } from './components/common/filters/status-filter/status-filter.component';
import { YearFilterComponent } from './components/common/filters/year-filter/year-filter.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { SortDirective } from './Common/SortDirective';
import { PaymentFilterComponent } from './components/common/filters/payment-filter/payment-filter.component';
import { TagFilterComponent } from './components/common/filters/tag-filter/tag-filter.component';
import { LoadingSpinnerComponent } from './components/common/visual-loading/loading-spinner/loading-spinner.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '919de41c-4399-4183-8334-bd1167d351ad',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      knownAuthorities: [b2cPolicies.authorityDomain],
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...apiConfig.scopes],
    },
    loginFailedRoute: 'login-failed',
  };
}
@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    NgbdSortableHeaderDirective,
    FileUploadButtonComponent,
    RequestIllustrationComponent,
    FileUploadTaskDetailComponent,
    FileUploadTaskListComponent,
    InformativeListComponent,
    InformativeDetailComponent,
    InformativeItemComponent,
    HeaderComponent,
    CommentItemComponent,
    CommentListComponent,
    CommentDetailComponent,
    ReportsComponent,
    OverviewComponent,
    IllustrationListComponent,
    IllustrationDetailComponent,
    BooleanTaskDetailComponent,
    BooleanTaskListComponent,
    ClientsComponent,
    ClientsDetailComponent,
    ClientsSearchComponent,
    LoginFailedComponent,
    ServiceFeesListPageComponent,
    PaymentsListPageComponent,
    ApprovalsListPageComponent,
    AccountInputListPageComponent,
    PoolingPermissionsListPageComponent,
    FaqComponent,
    NetworkMemberFilterComponent,
    FaqCategoriesComponent,
    ConfirmationModalComponent,
    MessageBoxComponent,
    NotFoundComponent,
    ServiceFeesDetailPageComponent,
    PaymentsDetailPageComponent,
    PoolingPermissionsDetailPageComponent,
    AccountInputDetailPageComponent,
    ApprovalsDetailPageComponent,
    AttachmentListComponent,
    ModalBasicComponent,
    AttachmentListComponent,
    UnauthorizedComponent,
    IllustrationInputDetailPageComponent,
    IllustrationInputListPageComponent,
    RefreshTaskViewComponent,
    UsersSearchComponent,
    MultipleFilterSearchComponent,
    EsignatureComponent,
    FinalizedAccountsDetailPageComponent,
    FinalizedAccountsListPageComponent,
    ReplacePipe,
    FooterComponent,
    ReplacePipe,
    StatusFilterComponent,
    YearFilterComponent,
    CookieConsentComponent,
    TermsAndConditionsComponent,
    SortDirective,
    PaymentFilterComponent,
    TagFilterComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    MsalModule,
    PowerBIEmbedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    ModalModule.forRoot(),
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgbModule,
    TextFieldModule,
    NgxEditorModule,
    QuillModule.forRoot(),
    FileSaverModule,
    NgxDocViewerModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    // { provide: API_BASE_URL, useValue: 'https://insurope-collaboration-platform-dev.azurewebsites.net' },
    { provide: API_BASE_URL, useValue: '' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    CookieService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],

})
export class AppModule { }
