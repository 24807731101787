import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-status-filter',
  templateUrl: './status-filter.component.html',
  styleUrls: ['./status-filter.component.scss']
})
export class StatusFilterComponent implements OnInit {
  searchingStatus: boolean = false;
  options: TasksStatus [] = [TasksStatus.Open, TasksStatus.InProgress, TasksStatus.Finalized,TasksStatus.Closed,null  ];
  filteredOptions:TasksStatus []= this.options;
  selected:TasksStatus = null;
  @Input() networkMemberId: string = "";
  @Input() inOverview: boolean = false;
  @ViewChild('searchField', { static: true }) searchInputField: ElementRef;
  @Output() subscriberId = new EventEmitter<string>();
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService : CommonService) { }

  async ngOnInit() {
    var storageStatus = localStorage.getItem("status");
    if(storageStatus == "null"){
      this.selected = null;
    }else{
      this.selected = parseInt(localStorage.getItem("status"))
    }
    
  }
  selectStatus(event){
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { status: event, },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.selected = event;
    this.filteredOptions = this.options;
    localStorage.setItem("status",event);
      
  }

  
}