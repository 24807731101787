<div class="alert alert-{{ type }} alert-dismissible fade show mt-3" role="alert">
    
    <h3 class="alert-heading mb-1">{{ title }}</h3>
    
    <p>{{ message }}</p>
    
    <p class="mb-0 d-flex">
        <button type="button" *ngIf="btnCancelText" (click)="decline()" class="btn btn-danger">{{ btnCancelText }}</button>
        <button type="button" *ngIf="btnOkText" (click)="accept()" class="btn btn-success ml-2">{{ btnOkText }}</button>
    </p>

    <button *ngIf="dismissable" (click)="close()" type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>

</div>