<div *ngIf="loadingAccount || notAuthenticated||userService.isUnauthorized;else loggedIn"
  class="w-100 text-center pt-5 position-absolute top-0 start-0">
  <h2 *ngIf="loadingAccount">Hello, we are checking your authentication.</h2>
  <h2 *ngIf="notAuthenticated">You are not authenticated and you will be redirected to the login page.</h2>
  <app-unauthorized class="w-100" *ngIf="userService.isUnauthorized"></app-unauthorized>
</div>

<ng-template #loggedIn>
  <div *ngIf="userService.loadedUser && !userService.isUnauthorized  && !isIframe">
    <app-terms-and-conditions *ngIf="!termsAndConditionsAccepted" ></app-terms-and-conditions>
    <app-nav-menu ></app-nav-menu>
    <app-header></app-header>
    <main>
      <router-outlet *ngIf="!isIframe"></router-outlet>
      <!-- <app-unauthorized *ngIf="userService.isUnauthorized || notAuthorized"></app-unauthorized> -->
      <app-cookie-consent></app-cookie-consent>
    </main>
  </div>
</ng-template>