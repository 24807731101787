import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IllustrationService } from 'src/app/services/illustration/illustration.service';
import { DOCUMENT, Location } from "@angular/common";
import { AccountDto, CountryDto, CustomFile, IllustrationRequestDto, SubscriberConfigDto, UserDto } from 'src/app/web-api-client';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NetworkMembersService } from 'src/app/services/network-members/network-members.service';
import { Title } from '@angular/platform-browser';
import { MessageType, PopupNotificationsService } from 'src/app/services/popup-notifications/popup-notifications.service';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { UserRoles } from 'src/app/models/enums/user-roles';

@Component({
  selector: 'app-request-illustration',
  templateUrl: './request-illustration.component.html',
  styleUrls: ['./request-illustration.component.scss']
})

export class RequestIllustrationComponent implements OnInit, OnDestroy {

  // hold list of clients
  filteredClients: AccountDto[] = [];

  // hold list of countries
  filteredCountries: CountryDto[] = [];

  // determine if list needs to be shown
  showClients: boolean = false;

  // hold the selected client
  selectedClient: AccountDto;

  // hold the uploaded files
  uploadedFiles: CustomFile[] = [];

  // hold the selected country
  selectedCountry: CountryDto;

  // boolean if it's a new client
  checkIsProspect: boolean = false;

  // search indicators
  searchingClient: boolean = false;
  searchingCountry: boolean = false;

  // new client boolean
  newClient: boolean = false;
  // sending request boolean
  sendingRequest: boolean = false;

  // show the warning message or not
  showWarningMessage: boolean = false;
  faCircleXmark = faCircleXmark;
  clickedOnClient = false;
  isCollaborator: boolean = false;
  displaySubs: UserDto[] = []
  illustrations: IllustrationRequestDto[] = [];
  currentUserId: string
  dragAreaClass: string;
  draggedFiles: any;
  // take the search field as input
  @ViewChild('searchClientField', { static: true }) searchInputClientField: ElementRef;
  @ViewChild('searchCountryField', { static: true }) searchInputCountryField: ElementRef;

  // hold list of subscriptions
  subscriptions: Subscription[] = [];

  // declare the form
  illustrationForm = new UntypedFormGroup({
    ClientName: new UntypedFormControl('', [Validators.required]),
    AdditionalComment: new UntypedFormControl('', [Validators.required]),
    InsuropeTypeOfBusiness: new UntypedFormControl('', [Validators.required]),
    Country: new UntypedFormControl('', [Validators.required])
  });
  params: ParamMap;
  loadingIllustrations: boolean;
  networkMember: AccountDto;
  page: number;
  isNetworkMember: boolean;
  pageSize: number;
  hideShowMoreBtn: boolean;
  networkMemberId;
  insuropeSubscribers: SubscriberConfigDto[] = []
  networkMemberSubscribers: SubscriberConfigDto[] = []
  // constructor
  constructor(private accountsService: AccountsService,
    private illustrationService: IllustrationService,
    public location: Location,
    private router: Router,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    private networkMemberService: NetworkMembersService,
    private titleService: Title,
    private popupNotificationsService: PopupNotificationsService,
    private activatedroute: ActivatedRoute,
    private networkMembersService: NetworkMembersService) { }

  ngAfterViewInit() {

    // body onclick event
    this.document.getElementsByTagName('body')[0].onclick = (event) => {
      // if the target is not the search client input
      if (event.target != this.document.getElementById('searchClientName')) {
        this.filteredClients = [];
        this.newClient = true;
      }
      // if the target is not the search country input
      if (event.target != this.document.getElementById('searchCountryName')) {
        this.filteredCountries = [];
      }
    };

  }

  // oninit method
  async ngOnInit(): Promise<void> {

    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);
    this.isNetworkMember = await this.userService.isUserInRole(UserRoles.NetworkMember);
    // set the title
    this.titleService.setTitle('Illustration Request | Insurope');
    this.activatedroute.queryParamMap.subscribe(async (params) => this.loadIllustrations(params));
    // get the connected user networkmember id
    this.networkMemberId = (await this.userService.getCurrentUser()).networkMemberIds;
    var currentUser = (await this.userService.getCurrentUser())
    this.currentUserId = currentUser.id
    this.displaySubs.push(currentUser)
    // get tht network member from the id
    const networkMember = await this.networkMemberService.getNetworkMemberById(this.networkMemberId[0]);
    this.networkMember = networkMember
    // if the network memeber has only one country
    if (networkMember.countries.length == 1) {
      // set the selected country
      this.selectedCountry = networkMember.countries[0]
      // set the country name in the field
      this.illustrationForm.controls.Country.setValue(networkMember.countries[0].name);
    }

    this.subscriptions.push(fromEvent(this.searchInputClientField.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // if character length greater then 2
      filter(res => res.length > 2),
      // Time in milliseconds between key events
      debounceTime(100),
      // If previous query is diffent from current   
      distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.fetchClient(text);
      this.selectedClient = null;
      this.clickedOnClient = false;
    }));


    this.subscriptions.push(fromEvent(this.searchInputCountryField.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      }),
      // Time in milliseconds between key events
      debounceTime(100),
      // If previous query is diffent from current   
      distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.fetchCountry(text);
    }));

  }

  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    this.dragAreaClass = "droparea";
    event.preventDefault();
  }

  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
  }

  @HostListener("drop", ["$event"]) onDrop(event: any) {
    this.dragAreaClass = "dragarea";
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: File[] = event.dataTransfer.files;
      this.fileUploaded({ isUploaded: true, message: "", files: files });
    }
  }

  // on destroy method
  ngOnDestroy() {
    // unsubscribe from subscriptions
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  async loadClientByName(accountName) {
    this.clickedOnClient = true;

    localStorage.setItem('clientName', accountName);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedroute,
        queryParams: { clientName: accountName },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.searchInputClientField.nativeElement.value = accountName;
  }

  async loadIllustrations(params?: any, loadMore?: boolean) {
    if (params) {
      this.params = params;
    }
    else {
      params = this.params;
    }

    let newItems;

    // loading started
    this.loadingIllustrations = true;

    if (params?.get("networkMemberId") && params.has("clientId")) {
      this.networkMember = await this.networkMembersService.getNetworkMemberById(params?.get("networkMemberId"));
    }
    var nmId = (params?.get("networkMemberId") ?? localStorage.getItem('networkMemberId'));
    var clientName = params?.get("clientName");
    var subscriberId = params?.get('subscriberId');
    if (subscriberId) {
      var subscriberEmail = (await this.userService.getUserById(subscriberId)).preferredEmail;
    }


    if (!loadMore) {
      this.illustrations = [];
      this.page = 1
    }

    if (this.isNetworkMember) {
      // load the illustrations
      newItems = await this.illustrationService.getMyOrganizationIllustrationsPaginated(
        clientName,
        null,
        subscriberId,
        subscriberEmail,
        null,
        null,
        this.page,
        this.pageSize
      );
    }
    if (newItems.length < this.pageSize) {
      this.hideShowMoreBtn = true;
    } else {
      this.hideShowMoreBtn = false;
    }

    let updatedItems = newItems.map(illustration => {
      let updatedIllustration = illustration;

      if (illustration.illustrationStage.name != "Finalized") {
        updatedIllustration.illustrationStage.name = "In-progress";
      }

      return updatedIllustration;
    });

    this.illustrations = this.illustrations.concat(updatedItems);

    if (this.illustrations.length == 0) {
      document.getElementById("illustrations-view-btn")?.classList.add("d-none");
    }
    else {
      document.getElementById("illustrations-view-btn")?.classList.remove("d-none");
    }

    // loading finished
    this.loadingIllustrations = false;

  }

  async clearSearch() {
    localStorage.removeItem('clientId');
    localStorage.removeItem('clientName');
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedroute,
        queryParams: { clientName: null, clientId: null },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

  }

  async addSubscriber(event: { id: string, user: UserDto }) {
    this.displaySubs.push(event.user);
  }

  // when searching for a user
  async fetchClient(term: string) {
    if (term === '') {
      return this.filteredClients = [];
    }
    this.newClient = false;
    this.searchingClient = true;
    this.filteredClients = await this.accountsService.getPooledAndProspectAccountsByName(term.toLowerCase(), 0, 10);
    this.searchingClient = false;
  }

  // when searching for a country
  async fetchCountry(term: string) {
    if (term === '')
      return this.filteredCountries = [];
    this.searchingCountry = true;
    console.log(this.networkMember);

    this.filteredCountries = this.networkMember.countries.filter(x => x.name.toLowerCase().includes(term.toLowerCase()));
    this.searchingCountry = false;
  }

  // called when selecting a client
  selectClient(client: AccountDto) {
    // hold the selected client
    this.selectedClient = client;
    // empty the list of clients
    this.filteredClients = [];
    if (client) {
      // set the client name
      this.searchInputClientField.nativeElement.value = client.accountName;
      this.illustrationForm.controls.ClientName.setValue(client.accountName);
      this.loadClientByName(client.accountName)
      this.newClient = false;
    }
    else {
      this.newClient = true;
    }
  }

  // called when selecting a country
  selectCountry(country: CountryDto) {
    // hold the selected country
    this.selectedCountry = country;
    // empty the list of countries
    this.filteredCountries = [];
    // set the country name
    this.searchInputCountryField.nativeElement.value = country.name;
    this.illustrationForm.controls.Country.setValue(country.name);
  }

  // when the file is uploaded
  fileUploaded(response: {
    isUploaded: boolean;
    message: string;
    files?: File[];
  }) {
    // if files where uploaded
    if (response.isUploaded) {
      // loop through the files
      for (let i = 0; i < response.files.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(response.files[i]);
        reader.onload = () => {
          // create new file
          let file = new CustomFile();
          file.fileName = response.files[i].name;
          file.file = reader.result.toString().split("base64,")[1];
          // add the file to the list
          this.uploadedFiles.push(file);
        };
      }
    }
  }

  // method to remove a file
  removeFile(index: number) {
    // remove the file
    this.uploadedFiles.splice(index, 1);
  }

  // method to save the illustration
  save() {

    if (this.uploadedFiles.length == 0) {
      this.showWarningMessage = true;
    }
    else {
      this.showWarningMessage = false;
      this.createIllustration();
    }

  }

  deleteSubscriber(email) {
    var index = this.displaySubs.indexOf(email)
    this.displaySubs.splice(index, 1)
  }

  async createIllustration() {
    // hide message
    this.showWarningMessage = false;

    // start the sending
    this.sendingRequest = true;

    // take the data from the form
    let data = this.illustrationForm.getRawValue();

    // list of countries
    let countriesId: string[] = [this.selectedCountry.iso2];

    let networkMemberId = (await this.userService.getCurrentUser()).networkMemberId;

    for (let index = 0; index < this.displaySubs.length; index++) {
      let subConfig = new SubscriberConfigDto();
      subConfig.id = this.displaySubs[index].id;
      subConfig.isManuallyAdded = true;

      if (this.displaySubs[index].roles.includes("Collaborator")) {
        this.insuropeSubscribers.push(subConfig);
      }

      if (this.displaySubs[index].roles.includes("NetworkMember")) {
        this.networkMemberSubscribers.push(subConfig)
      }
    }
    // create the illustration
    this.illustrationService.createIllustration(this.selectedClient?.id, data["ClientName"], data["AdditionalComment"], data["InsuropeTypeOfBusiness"], countriesId, networkMemberId, this.uploadedFiles, this.insuropeSubscribers, this.networkMemberSubscribers).then(response => {
      // if the response is successful
      if (response.status == 200) {
        // show the success message
        this.popupNotificationsService.showMessage("Illustration sent successfully!", MessageType.Success);
        // redirect to the dashboard
        this.router.navigate(['']);
      }
      else {
        console.error(response);
      }
      // stop the sending
      this.sendingRequest = false;
    });

    // clear the form
    this.illustrationForm.reset();
    this.clearSearch();
    this.uploadedFiles = [];
  }
}
