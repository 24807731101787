<section class="filter-labelled">
    <label for="networkMemberInputId" class="ps-3 fs-5">Tag filter</label>
    <div class="d-flex filter-box custom-filter">

        <div class="input-group"> 
            <input type="text" placeholder="Search" class="form-control" #searchField id="tagInputId" (keydown.enter)="addTag()">

            <button type="button" class="btn-close ms-1 mt-2" aria-label="Close" (click)="clearSearch()" *ngIf="searchField.value || selectedTags.length">
                <span aria-hidden="true"></span>
            </button>
        </div>

        <div class="d-flex flex-wrap mt-2 gap-2">
            <div class="d-flex align-items-center" *ngFor="let tag of selectedTags">
                <span class="badge text-dark d-flex justify-content-between align-items-center tag cursor-pointer">
                    {{ tag }}
                </span>
                <span (click)="removeTag(tag)" class="delete-subscriber text-danger ms-1">
                    <fa-icon [icon]="faCircleXmark" size="lg"></fa-icon>
                </span>
            </div>
        </div>

        <div *ngIf="searchingTags" class="mt-2 searching-spinner">
            <div class="spinner-border spinner-border-sm mr-1" role="status">
            </div>
            <span>Searching...</span>
        </div>
    </div>
</section>


