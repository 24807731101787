import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, map, filter, debounceTime, distinctUntilChanged, Subscription, Observable } from 'rxjs';
import { CommentService } from 'src/app/services/comment.service';
import { CommentDto, CommentTagDto } from 'src/app/web-api-client';
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss']
})
export class TagFilterComponent implements OnInit {
  searchingTags: boolean = false;
  filteredTags: CommentTagDto[] = [];
  clickedOnTag: boolean = false;
  selectedTags: string[] = [];

  tagSelectedSubscription: Subscription;

  @Input() discussionId: string;
  @Input() tagSelected: Observable<string> = new Observable<string>();
  @Output() filteredCommentsEventEmitter = new EventEmitter<CommentDto[]>();
  @ViewChild('searchField', { static: true }) searchInputField: ElementRef;

  faCircleXmark = faCircleXmark;

  constructor(public CommentService: CommentService) { }

  async ngOnInit() {
    this.searchInputField.nativeElement.value = "";
    this.tagSelectedSubscription = this.tagSelected.subscribe((tags: string) => { this.addTag(tags); });
  }

  async fetchTags() {
    this.clickedOnTag = false;
    this.searchingTags = true;

    if (this.selectedTags.length == 0) {
      this.filteredTags = [];
      this.filteredCommentsEventEmitter.emit(this.CommentService.comments);
      this.searchingTags = false;
      return;
    }

    const filteredComments = this.filterCommentsByTag();
    this.filteredCommentsEventEmitter.emit(
      filteredComments.sort((a, b) => b.messageSent.getTime() - a.messageSent.getTime())
    );
    this.searchingTags = false;
  }

  async clearSearch() {
    this.filteredTags = [];
    this.selectedTags = [];
    this.searchInputField.nativeElement.value = "";
    this.fetchTags();
  }

  ngOnDestroy() {
    this.tagSelectedSubscription.unsubscribe();
  }

  //filter with list tags
  filterCommentsByTag() {
    return this.CommentService.comments.filter(comment =>
      this.selectedTags.every(tag =>
        comment.tags.some(e => e.tagName.toLowerCase().startsWith(tag.toLowerCase()))
      )
    );
  }

  //add tags to list
  addTag(tags?: string) {
    var tagName: string;

    if (tags) {
      tagName = tags;
    }
    else {
      tagName = this.searchInputField.nativeElement.value.trim();
    }

    if (tagName && !this.selectedTags.includes(tagName)) {
      this.selectedTags.push(tagName);
      this.searchInputField.nativeElement.value = "";
      this.fetchTags();
    }
  }

  //Remove tags from list
  removeTag(tag: string) {
    this.selectedTags = this.selectedTags.filter(t => t !== tag);
    this.fetchTags();
  }
}
