<section class="filter-labelled">
<label for="subscriberInputId">Client</label>
    
    <div class="d-flex filter-box custom-filter">
        <div class="input-group">
        <input type="text" placeholder="Client name" class="form-control mb-2"  #searchClientField id="clientSearchText">
        <button type="button" class="btn-close ms-1 mt-2" aria-label="Close" (click)="clearSearch()" *ngIf="searchClientField.value">
            <span aria-hidden="true"></span>
        </button>
    </div>
    <div *ngIf="searchingClient && isOnClientView" class="mt-2 mb-2 searching-spinner">
        <div class="spinner-border spinner-border-sm mr-1" role="status">
        </div>
        <span>Searching...</span>
    </div>
    <ul *ngIf="!clickedOnClient && isOnClientView">
        <li *ngFor="let client of searchResult" (click)="loadClient(client)">
        <p> {{ client.accountName }}</p>
        </li>
    </ul>
    </div>
</section>

  



