import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { Consent } from 'src/app/web-api-client';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {

  redirectTo: string = "";
  acceptBtn: HTMLButtonElement;
  acceptCheckbox:HTMLInputElement;
  constructor(private userservice: UserService, private router: Router) { }

  ngOnInit(): void {
    //var approveAllConsentsCheckBox = <HTMLInputElement> document.getElementById("approveAllCheckBox");
    //approveAllConsentsCheckBox = <HTMLInputElement> document.getElementById("approveAllCheckBox");
    //approveAllConsentsCheckBox.addEventListener('change',(e)=>{this.allConsentsAreApproved = approveAllConsentsCheckBox.checked;})

    // Get the modal
    const modal = document.getElementById("myModal") as HTMLDivElement;
    // Get the accept button
    this.acceptBtn = document.getElementById("acceptBtn") as HTMLButtonElement;
    // Get the accept checkbox
    this.acceptCheckbox = document.getElementById("acceptCheckbox") as HTMLInputElement;

    // When the page loaded, open the modal 
    modal.style.display = "block";
    // When the user checks/unchecks the checkbox, toggle accept button
    this.acceptCheckbox.addEventListener('change',()=>{this.acceptBtn.disabled = !this.acceptCheckbox.checked;});

    // Initially disable the accept button

  }

  async acceptTermsAndCondtions() {
    let timeNow = new Date(new Date().toUTCString());

    var consent = new Consent();
    consent.approved = true;
    consent.approvedDate = timeNow;

    var userId = await this.userservice.getCurrentUserId();
    var newUser = await this.userservice.upsertUser(userId, consent, consent);

    if (localStorage.getItem("RedirectUrl")) {
      this.redirectTo = localStorage.getItem("RedirectUrl");
      localStorage.removeItem("RedirectUrl");
    }

    this.router.navigateByUrl(this.redirectTo);
  }

  refuseTermsAndCondtions() {
    this.userservice.logout();
  }
}



