import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import { faTh, faBars } from '@fortawesome/free-solid-svg-icons';
import { UserRoles } from 'src/app/models/enums/user-roles';

@Component({
  selector: 'app-finalized-accounts-list-page',
  templateUrl: './finalized-accounts-list-page.component.html',
  styleUrls: ['./finalized-accounts-list-page.component.scss']
})

export class FinalizedAccountsListPageComponent implements OnInit {
  isTableViewStoredValueKey: string;
  process = "annual-report"
  @Input() page: number = 1;
  @Input() pageSize: number = 5;
  isCollaborator: boolean;
  statusFilter: number;
  isTableView: boolean = false;
  //headers: string[] = ["Client", "Network Member", "Status", "created Date", "Sent NM Date"];
  headers: { head: string, property: string }[] = [{ head: "Client", property: "title" }, { head: "Network Member", property: "networkMember?.accountName" }, { head: "Status", property: "status.value" }, { head: "Sent NM Date", property: "metadata" }, { head: "Sent Client Date", property: "metadata" }]
  faTh = faTh;
  faBars = faBars;

  constructor(private userService: UserService, private titleService: Title) {
  }

  async ngOnInit() {
    // set the title
    this.titleService.setTitle("Annual Reports | Insurope");
    this.isTableViewStoredValueKey = this.titleService.getTitle() + '_isTableView'
    this.isCollaborator = await this.userService.isUserInRole(UserRoles.Collaborator);

    this.statusFilter = this.isCollaborator ? null : 0;
    var storedValue = JSON.parse(localStorage.getItem(this.isTableViewStoredValueKey))
    this.changeIsTableViewValue(storedValue);
  }

  changeIsTableViewValue(value) {
    if (value == null) {
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(this.isTableView));
    } else {
      this.isTableView = value;
      localStorage.setItem(this.isTableViewStoredValueKey, JSON.stringify(value));
    }
  }
}
