import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbAlertModule, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { parseDate } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-year-filter',
  templateUrl: './year-filter.component.html',
  styleUrls: ['./year-filter.component.scss']
})
export class YearFilterComponent implements OnInit {
	model: NgbDateStruct;
  dateInput : Date
  @ViewChild('yearField', { static: true }) searchInputField: ElementRef;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    var dateString = localStorage.getItem('dueDate')
    if(dateString != null){
      this.dateInput = parseDate(dateString+"-01-01");
    }else{
      this.dateInput = null;
    }
   
  }
  async clearSearch() {
    localStorage.removeItem('dueDate');
    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: { dueDate: null },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.searchInputField.nativeElement.value = "";
    this.dateInput = null;
  }
  changeDate(event:Date){
  var year = event.getFullYear();
  localStorage.setItem('dueDate',year.toString())
  this.router.navigate(
    [],
    {
      relativeTo: this.activatedRoute,
      queryParams: { dueDate: year, },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  
  }
}
