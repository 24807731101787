import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from 'src/app/services/comment.service';
import { InformativeService } from 'src/app/services/informative.service';
import { GeneralTaskDto, TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-service-fees-detail-page',
  templateUrl: './service-fees-detail-page.component.html',
  styleUrls: ['./service-fees-detail-page.component.scss']
})
export class ServiceFeesDetailPageComponent implements OnInit {

  // hold the taskId
  taskId: string;
  networkMemberId: any;
  networkMemberName: string;
  clientName: string;
  status: TasksStatus;
  isLoading: boolean = false;
  task: GeneralTaskDto;

  // constructor
  constructor(private activatedroute: ActivatedRoute, public informativeTaskService: InformativeService, private commentService: CommentService) {
    this.informativeTaskService.activeTask = null;
  }

  // oninit method
  async ngOnInit() {
    this.isLoading = true;

    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    this.task = await this.informativeTaskService.getInformative(this.taskId);
    this.commentService.task = this.task;
    this.clientName = this.task.title;
    this.status = this.task.status.value;

    let networkMembers = this.task.networkMember;
    this.networkMemberId = networkMembers?.id;
    this.networkMemberName = networkMembers?.accountName;

    this.commentService.discussion = await this.commentService.getDiscussionByTaskId(this.task.id);
    this.commentService.taskSubscribers = await this.commentService.getTaskSubescribersByDiscussionId(this.task.id);

    this.isLoading = false;
  }
}
