/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_signupSignin',
    editProfile: '',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://insuropeb2c2.b2clogin.com/insuropeb2c2.onmicrosoft.com/B2C_1_signupSignin',
    },
    editProfile: {
      authority:
        'https://insuropeb2c2.b2clogin.com/insuropeb2c2.onmicrosoft.com/B2C_1_signin',
    },
  },
  authorityDomain: 'insuropeb2c2.b2clogin.com',
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: { scopes: string[]; uri: string } = {
  scopes: [
    'https://insuropeb2c2.onmicrosoft.com/919de41c-4399-4183-8334-bd1167d351ad/user-access',
  ],
  uri: '/',
  // uri: 'https://insurope-collaboration-platform-dev.azurewebsites.net',
};
