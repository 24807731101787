import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ContactsService } from 'src/app/services/contacts/contacts.service';
import { MessageType, PopupNotificationsService } from 'src/app/services/popup-notifications/popup-notifications.service';
import { AccountDto, ContactDto, CrmAttachmentDto, ExpansionPlanDto, PaginatedListOfCrmAttachmentDto, PaginatedListOfExpansionPlanDto, TechnicalContact } from 'src/app/web-api-client';
import * as XLSX from 'xlsx';
import { NgbdSortableHeaderDirective, SortEvent } from './ngbd-sortable-header.directive';

@Component({
  selector: 'app-clients-detail',
  templateUrl: './clients-detail.component.html',
  styleUrls: ['./clients-detail.component.scss']
})
export class ClientsDetailComponent implements OnInit {

  // take the client as input
  @Input() client: AccountDto;

  sortColumn: string;
  sortDirection: string;

  technicalContact: TechnicalContact;
  companyContact: ContactDto;
  consultantContact: ContactDto;
  loadingContacts: boolean = false;

  expansionPlans: PaginatedListOfExpansionPlanDto;
  loadingExpansionPlans: boolean = false;
  exportingExpansionPlans: boolean = false;

  // hold the attachments
  attachments: PaginatedListOfCrmAttachmentDto;
  attachmentsSizes: string[] = [];
  loadingAttachments: boolean = false;
  
  @Input() loadingImportantFiles: boolean = false;

  contractualDocuments: PaginatedListOfCrmAttachmentDto;
  contractualDocumentsSizes: string[] = [];
  loadingContractualDocuments: boolean = false;

  @Input() latestAnnualReportId: string;
  @Input() latestCompositionReportId: string;
  @Input() latestDividendPaymentLetterId: string;

  @Output() changeClientViewActiveTab = new EventEmitter<string>();

  // take the sortable headers
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;

  // hold the view to display
  viewToDisplay: string = 'info';

  // fa icons
  faDownload = faDownload;

  sizes: string[] = ["B", "KB", "MB", "GB", "TB" ];
  
  pageSize: number = 20;
  public expansionPlansPageNumber: number = 1;
  public attachmentsPageNumber: number = 1;
  public contractualDocumentsPageNumber: number = 1;

  // constructor
  constructor(private clientsService: ClientsService,
    private accountService: AccountsService,
    private popupNotifications: PopupNotificationsService,
    private contactsService: ContactsService) { }

  // oninit method
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.client && changes.client.currentValue) { //changes.client check if it has change and changes.client.currentValue check if it's null
      this.loadContacts();
      this.loadContractualDocuments(); 
      this.loadAttachments();
      this.loadExpansionPlans();
    }
  }
  
  // download an attachment
  async downloadAttachment(attachmentId: string) {
    this.loadingContractualDocuments = true;
    this.loadingAttachments = true;
    this.loadingImportantFiles = true;
    let file = await this.clientsService.downloadAttachment(attachmentId);
    const a = document.createElement('a');
    document.body.appendChild(a);
    const blob: any = new Blob([file.data], { type: file.data.type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = file.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    this.loadingContractualDocuments = false;
    this.loadingAttachments = false;
    this.loadingImportantFiles = false;
  }

  // change view method
  changeView(view: string) {
    this.changeClientViewActiveTab.emit(view);
    this.viewToDisplay = view;
  }

  async downloadLatestAccountingExcel() {
    try {
      this.loadingImportantFiles = true;
      let file = await this.accountService.getAccountExcelFile(this.client.externalAccountNumber);
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blob: any = new Blob([file.data], { type: file.data.type });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = file.fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error.status == 404) {
        this.popupNotifications.showMessage("There was no excel file found for this account.", MessageType.Warning);
      }
    }
    this.loadingImportantFiles = false;
  }

  async loadExpansionPlans(page?: number) {
    this.loadingExpansionPlans = true;
    if (page) {
      this.expansionPlansPageNumber = page;
    }
    this.expansionPlans = await this.accountService.getAccountExpansionPlan(this.client.id, this.expansionPlansPageNumber, this.pageSize, this.sortColumn, this.sortDirection);
    this.loadingExpansionPlans = false;
  }

  async loadAttachments(page?: number) {
    this.loadingAttachments = true;
    if (page) {
      this.attachmentsPageNumber = page;
    }
    this.attachments = await this.clientsService.getClientContracts(this.client.id, this.attachmentsPageNumber, this.pageSize);
    this.attachments.items.forEach(att => {
      let order = 0;
      let len = att.fileSize;
      while (len >= 1024 && order < this.sizes.length - 1) {
        order ++;
        len = len / 1024;
      }
      this.attachmentsSizes.push(((order == 0 || order == 1) ? len.toFixed() : len.toFixed(2)) + " " + this.sizes[order]);
    });
    this.loadingAttachments = false;
  }

  async loadContacts() {
    this.loadingContacts = true;
    try {
      if (this.client.externalAccountNumber) {
        this.technicalContact = await this.clientsService.getTechnicalContact(this.client.externalAccountNumber);
      }
    } catch (error) {}

    try {
      this.companyContact = await this.contactsService.GetPrimaryContactFromAccount(this.client.id);
    } catch (error) {
    }
    
    try {
      this.consultantContact = await this.contactsService.GetConsultantContactFromAccount(this.client.id);
    } catch (error) {
    }
    this.loadingContacts = false;
  }

  async loadContractualDocuments(page?: number) {
    this.loadingContractualDocuments = true;
    if (page) {
      this.contractualDocumentsPageNumber = page;
    }
    this.contractualDocuments = await this.clientsService.getClientContractualDocuments(this.client.id, this.contractualDocumentsPageNumber, this.pageSize);
    this.contractualDocuments.items.forEach(att => {
      let order = 0;
      let len = att.fileSize;
      while (len >= 1024 && order < this.sizes.length - 1) {
        order ++;
        len = len / 1024;
      }
      this.contractualDocumentsSizes.push(((order == 0 || order == 1) ? len.toFixed() : len.toFixed(2)) + " " + this.sizes[order]);
    });
    this.loadingContractualDocuments = false;
  }

  async exportexcel()
  {
    if (!this.exportingExpansionPlans)
    {
      this.exportingExpansionPlans = true;
      let elements = (await this.accountService.getAccountExpansionPlan(this.client.id, 1, this.expansionPlans.totalCount, null, null)).items.map(({ id, account, ...item }) => item);
      
      // create worksheet from the list
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(elements);
      
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Expansion plans');
      
      /* save to file */
      XLSX.writeFile(wb, this.client.accountName + ".xlsx");
      
      this.exportingExpansionPlans = false;
    }
  }

  // method called when sorting header click
  async onSort({ column, direction }: SortEvent) {
    // reset other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    await this.loadExpansionPlans(this.expansionPlansPageNumber);
    
  }

  counter(i: number) {
    return new Array(i);
}

}
