import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from 'src/app/services/comment.service';
import { FileUploadTaskService } from 'src/app/services/file-upload-task/file-upload-task.service';
import { FileUploadTaskDto, TasksStatus } from 'src/app/web-api-client';

@Component({
  selector: 'app-pooling-permissions-detail-page',
  templateUrl: './pooling-permissions-detail-page.component.html',
  styleUrls: ['./pooling-permissions-detail-page.component.scss']
})
export class PoolingPermissionsDetailPageComponent implements OnInit {
  // hold the taskId
  taskId: string;
  networkMemberId: string;
  networkMemberName: string;
  clientName: string;
  status: TasksStatus;
  loadingTask: boolean = false;
  task: FileUploadTaskDto;

  // constructor
  constructor(private titleService: Title, private activatedroute: ActivatedRoute, public fileUploadTaskService: FileUploadTaskService, private commentService: CommentService) {
    this.fileUploadTaskService.activeFileUploadTask = null;
  }

  async ngOnInit() {
    this.loadingTask = true;

    this.taskId = this.activatedroute.snapshot.paramMap.get("id");
    this.task = await this.fileUploadTaskService.getFileUploadTaskByIdAndSetActive(this.taskId);
    this.status = this.task.status.value;
    this.clientName = this.task.title;
    this.status = this.task.status.value;
    this.fileUploadTaskService.processId = this.task.processId;

    let networkMember = this.task.networkMember;
    this.networkMemberId = networkMember?.id;
    this.networkMemberName = networkMember?.accountName;

    this.commentService.task = this.task;

    this.commentService.discussion = await this.commentService.getDiscussionByTaskId(this.taskId);
    this.commentService.taskSubscribers = await this.commentService.getTaskSubescribersByDiscussionId(this.task.id);

    // set the title
    this.titleService.setTitle("Pooling Permission | Insurope");

    this.loadingTask = false;
  }
}
