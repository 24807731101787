<div class="container-fluid mt-6" style="background-color: white;padding: 20px; border:solid 1px #CFDDE6;border-radius: 10px;">
   <div class="row">
      <div class="col-lg-7 pr-lg-5">
         <div class="d-flex">
            <div class="flex-shrink-0">
               <div class="rounded-circle bg-secondary text-white d-flex align-items-center justify-content-center" style="width: 40px; height: 40px;">
                  {{this.pseudo}}
               </div>
            </div>
            <div class="flex-grow-1 ms-3">
               <h3 class="mb-1 pb-3">{{ comment?.firstName }} {{ comment?.lastName }}, {{ comment?.messageSent |date }}</h3>
               <p [innerHTML]="lessCommmentText"></p>
               <p *ngIf="showMore" [innerHTML]="moreCommmentText"></p>
               <button *ngIf="moreCommmentText.length>0" class="btn btn-link p-0" (click)="toggleShowMore()"> {{ showMore ? 'Show less' : 'Show more' }}</button>
            </div>
         </div>
      </div>
      <div class="col-lg-1 offset-lg-1">
         <!-- Empty space equivalent to 1 columns -->
      </div>
      <div class="col-lg-3 mt-3 mt-lg-0" *ngIf="isTagSystemApplicable">
         <h4 class="mb-2" style="font-size: medium;">Tags</h4>
         <div class="row row-cols-3 g-2">
            <div class="col-auto d-flex" *ngFor="let tag of commentTags">
               <span class="badge text-dark d-flex justify-content-between align-items-center tag cursor-pointer" (click)="filterByTag(tag.tagName)">
                  {{ tag.tagName }}
               </span>
               <span (click)="deleteTag(tag?.id)" class="delete-subscriber text-danger">
                  <fa-icon [icon]="faCircleXmark" size="lg"></fa-icon>
               </span>
            </div>
         </div>
         <div class="mt-2">
            <div class="input-group">
               <input id="typeahead-focus" type="text" class="mt-2 w-full px-3 py-2 border rounded-2 shadow-sm" [(ngModel)]="model" [ngbTypeahead]="search" (focus)="focus$.next($any($event).target.value)"
                  (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead" placeholder="Add a tag..." (keydown.enter)="addTag($event,$any($event).target.value)" (selectItem)="addTag($event,$event.item)"
                  onkeydown="return this.onKeyDown" [maxlength]="maxLength" />
            </div>
         </div>
      </div>
   </div>
   <div class="d-flex mt-5">
      <button class="btn btn-primary me-2" *ngIf="isTagSystemApplicable" (click)="addReplyTags()">Reply</button>
      <button class="btn btn-danger me-2" *ngIf="canBeDeleted" (click)="deleteComment()">Delete</button>
      <button class="btn btn-secondary" *ngIf="canBeEdited" (click)="editeComment()">Edit</button>
      <div class="attachment ms-3 justify-content-center">
         <a class="me-2" *ngFor="let attachment of attachments" (click)="downloadCommentFile(attachment)">
            {{ documentService.sanitizeFileName(attachment?.name)}}
         </a>
      </div>
   </div>
</div>